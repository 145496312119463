import React, { type ReactNode } from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import Placeholder from '@atlassian/jira-placeholder';

type Props = {
	fallback?: ReactNode;
	children: ReactNode;
	screenName: string;
};

const VersionFilterRelayBoundary = ({ fallback = null, children, screenName }: Props) => (
	<JSErrorBoundary
		id="version-filter-relay-dropdown"
		packageName="software-filters"
		teamName="a4t-tanuki"
		fallback="flag"
		extraEventData={{ screenName }}
	>
		<Placeholder name="version-filter-relay-boundary" fallback={fallback}>
			{children}
		</Placeholder>
	</JSErrorBoundary>
);

export default VersionFilterRelayBoundary;
