import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { SOFTWARE_PROJECT } from '@atlassian/jira-common-constants/src/project-types';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import {
	getAttributes,
	getSource,
} from '@atlassian/jira-product-analytics-bridge/src/utils/extract-data-from-event';

// TODO: PNDR-2101 - Remove when cleaning up JSW CMP card cover changeboarding
type JswCmpCardCoversExpCohort = 'experiment' | 'feature-hidden' | 'changeboarding-disabled';

// TODO: PNDR-2101 - Remove when cleaning up JSW CMP card cover changeboarding
export const getJswCmpCardCoversExpCohort = (
	event: UIAnalyticsEvent,
): JswCmpCardCoversExpCohort => {
	if (!fg('jsw_cmp_card_cover_images')) {
		return 'feature-hidden';
	}

	const { projectType, isClassic } = getAttributes(event);
	const source = getSource(event);

	if (!(projectType === SOFTWARE_PROJECT && isClassic && source === 'boardScreen')) {
		return 'feature-hidden';
	}

	if (!fg('jsw_cmp_card_covers_changeboarding')) {
		return 'changeboarding-disabled';
	}

	return expVal('jsw_cmp_card_cover_images_experiment', 'isCardCoverFeatureEnabled', false)
		? 'experiment'
		: 'feature-hidden';
};
