import React, { useEffect, useMemo } from 'react';
import { createSelector } from 'reselect';
import memoizeOne from 'memoize-one';
import { getNormalisedPerformanceFFs } from '@atlassian/jira-common-long-task-metrics/src/common/util/collectors';
import { fg } from '@atlassian/jira-feature-gating';
import { useViewMode } from '@atlassian/jira-issue-context-service/src/main.tsx';
import SubmitApdex from '@atlassian/jira-providers-spa-apdex-analytics/src/submit-apdex-mark/index.tsx';
import { connect } from '@atlassian/jira-react-redux';
import { useQueryParam } from '@atlassian/jira-router';
import type { BoardFeatureGQL } from '@atlassian/jira-software-board-fetch-scope-critical/src/types.tsx';
import { pushEarlyMarksToBrowserMetrics } from '@atlassian/jira-software-browser-metrics/src/services/push-early-marks-to-browser-metrics/index.tsx';
import { getFeatureAnalyticsAttributes } from '@atlassian/jira-software-feature-analytics/src/common/utils.tsx';
import { BOARD } from '@atlassian/jira-software-resource-invalidator/src/common/types.tsx';
import { getAndClearInvalidationReasons } from '@atlassian/jira-software-resource-invalidator/src/controllers/resources-cache-invalidation-subject/index.tsx';
import {
	emitApdexOnAnimationFrame,
	isAlternateTTIMeasurementEnabled,
	isErrorTrackingEnabledWithSentry,
	isPrefetchIssueDataByColumnLimitEnabled,
} from '../../../feature-flags';
import type { ColumnWithResolution } from '../../../model/column/column-types';
import {
	nextGenBoardPageLoad,
	uifBoardNewUiPageLoad,
	incrementPlanningBoardPageLoad,
} from '../../../services/utils/performance-analytics';
import {
	getBoardConfig,
	getIsCMPBoard,
	rapidViewIdSelector,
	sessionIdSelector,
	getIsIncrementPlanningBoard,
} from '../../../state/selectors/software/software-selectors';
import {
	workIssuesSelector,
	columnsWithResolutionSelector,
} from '../../../state/selectors/work/work-selectors';
import type { State } from '../../../state/types';

type StateProps = {
	extra: {
		[key: string]: number | string | boolean;
	};
	columns: ColumnWithResolution[];
	isCMPBoard: boolean;
	isIncrementPlanningBoard: boolean;
};

export type OwnProps = {
	isCacheHit: boolean;
};

type Props = OwnProps & StateProps;

const getHasServiceWorkerController = (): boolean => {
	try {
		return !!navigator?.serviceWorker?.controller;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (err: any) {
		return false;
	}
};

const getExtra = memoizeOne(
	(
		isCacheHit: boolean,
		issueNo: number,
		boardSessionId: string,
		boardId: string,
		allFeatures,
		isCMPBoard: boolean,
	) => {
		const allFeaturesReadOnly: Readonly<typeof allFeatures> = { ...allFeatures };
		return {
			...getNormalisedPerformanceFFs(),
			...allFeaturesReadOnly,
			isCacheHit,
			cacheInvalidationReasons: getAndClearInvalidationReasons(BOARD).join(','),
			issueNo,
			boardSessionId,
			boardId,
			hasServiceWorkerController: getHasServiceWorkerController(),
			isSentryEnabled: isErrorTrackingEnabledWithSentry(),
			isPrefetchIssueDataByColumnLimitEnabled: isPrefetchIssueDataByColumnLimitEnabled(),
			isUIF: true,
			isClassic: isCMPBoard,
		};
	},
);

const getFeatureAnalyticsAttributesMemoized = () =>
	createSelector(
		(allFeatures: BoardFeatureGQL[]) => getFeatureAnalyticsAttributes(allFeatures),
		(res) => res,
	);

export const mapStateToProps = () => {
	const getFeatureAnalyticsAttributesInstance = getFeatureAnalyticsAttributesMemoized();
	return (state: State, ownProps: OwnProps): StateProps => {
		const { isCacheHit } = ownProps;
		const issueNo = workIssuesSelector(state).length;

		const isCMPBoard = getIsCMPBoard(state);
		const boardSessionId = sessionIdSelector(state);
		const boardId = rapidViewIdSelector(state);
		const allFeatures = getFeatureAnalyticsAttributesInstance(getBoardConfig(state).allFeatures);
		const columns = columnsWithResolutionSelector(state);
		const isIncrementPlanningBoard = getIsIncrementPlanningBoard(state);

		return {
			extra: getExtra(isCacheHit, issueNo, boardSessionId, boardId, allFeatures, isCMPBoard),
			columns,
			isCMPBoard,
			isIncrementPlanningBoard,
		};
	};
};

// Depending on feature flags, used to:
// 1. Also fire BM3 metric.mark() for custom timings, on top of performance.mark() invocations
export const SubmitApdexConnectedWithMountEffect = ({
	columns,
	extra,
	isCMPBoard,
	isIncrementPlanningBoard,
}: Props) => {
	const issueViewMode = useViewMode() || '';
	const [issueKey] = useQueryParam('selectedIssue');
	const extraIssueViewData = useMemo(
		() => ({ issueViewMode, isIssueViewOpen: !!issueKey }),
		[issueKey, issueViewMode],
	);

	useEffect(() => {
		if (isCMPBoard) {
			pushEarlyMarksToBrowserMetrics(uifBoardNewUiPageLoad);
		} else if (isIncrementPlanningBoard) {
			pushEarlyMarksToBrowserMetrics(incrementPlanningBoardPageLoad);
		} else {
			pushEarlyMarksToBrowserMetrics(nextGenBoardPageLoad);
		}
	}, [isCMPBoard, isIncrementPlanningBoard]);

	let metric;
	switch (true) {
		case isCMPBoard:
			metric = uifBoardNewUiPageLoad;
			break;
		case isIncrementPlanningBoard:
			metric = incrementPlanningBoardPageLoad;
			break;
		default:
			metric = nextGenBoardPageLoad;
	}

	if (columns.length > 0) {
		return (
			<SubmitApdex
				extra={
					fg('add_issue_view_data_in_board_ufo_event') ? { ...extra, ...extraIssueViewData } : extra
				}
				appName="software-board"
				// @ts-expect-error - TS2322 - Type 'PageLoadMetric' is not assignable to type 'BM3Metric'.
				metric={metric}
				emitOnAnimationFrame={emitApdexOnAnimationFrame()}
				waitForLongTaskIdleTTI={isAlternateTTIMeasurementEnabled()}
				testId="software-board.board-container.submit-apdex.submit-apdex"
			/>
		);
	}

	return null;
};

export default connect(mapStateToProps, {})(SubmitApdexConnectedWithMountEffect);
