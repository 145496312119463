import React from 'react';
import type { GoalState } from '@atlassian/jira-shared-types/src/goal.tsx';
import {
	mapStateToBgColor,
	mapStateToColor,
	extractStatusLabelWithoutScore,
} from '../../../common/utils';
import { Score } from './score';
import { Tracker } from './styled';

export const GoalTracker = ({ value, label, score }: GoalState) => (
	<Tracker backgroundColor={mapStateToBgColor(value)} color={mapStateToColor(value)}>
		{extractStatusLabelWithoutScore(label)}
		{score !== undefined && score !== null && <Score score={score} />}
	</Tracker>
);
