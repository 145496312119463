import type { Action, StoreActionApi } from '@atlassian/react-sweet-state';
import type { View } from '../../common/types/constant.tsx';
import type { SetViewSettingsCardExtraFieldsProps } from '../../common/types/settings';
import {
	ViewSetting,
	type SetViewSettingsIsFullscreenProps,
	type SetViewSettingsPanelsProps,
	type SetViewSettingsQuickFiltersProps,
	type SetViewSettingsCompactModeProps,
	type SetViewSettingsCardDetailsProps,
	type SetViewSettingsShowCardCoversProps,
	type ConfigureViewSettingsProps,
	type ToggleViewSettingProps,
} from '../../common/types/settings.tsx';
import type { State, IssueId } from '../../common/types/state.tsx';
import * as storage from '../../services/local-storage';

export const initState =
	() =>
	({ setState }: StoreActionApi<State>, { view }: { view: (typeof View)[keyof typeof View] }) => {
		setState({
			view,
		});
	};

export const setViewSettings =
	(newState: State): Action<State> =>
	({ setState }) => {
		setState(newState);
	};

export const setIsFullscreen =
	({ boardId, view, isFullscreen }: SetViewSettingsIsFullscreenProps): Action<State> =>
	({ setState, getState }) => {
		const currentSettings = getState();
		storage.setViewSettingsIsFullscreen({
			boardId,
			isFullscreen,
			view: view || currentSettings.view || undefined,
		});
		setState({
			...currentSettings,
			isFullscreen,
		});
	};

// Toggles panel(s) state - updating both localStorage state and sweet-state
export const setViewSettingsPanels =
	({ boardId, view, panelState }: SetViewSettingsPanelsProps): Action<State> =>
	({ setState, getState }) => {
		const currentSettings = getState();
		storage.setViewSettingsPanels({
			boardId,
			panelState,
			view: view || currentSettings.view || undefined,
		});
		setState({
			...currentSettings,
			showEpicPanel: panelState.EPIC_PANEL,
			showVersionPanel: panelState.VERSION_PANEL,
		});
	};

export const setViewSettingsQuickFilters =
	({ boardId, view, showQuickFilters }: SetViewSettingsQuickFiltersProps): Action<State> =>
	({ setState, getState }) => {
		const currentSettings = getState();
		storage.setViewSettingsQuickFilters({
			boardId,
			showQuickFilters,
			view: view || currentSettings.view || undefined,
		});
		setState({
			...currentSettings,
			showQuickFilters,
		});
	};

export const setViewSettingsCompactMode =
	({ boardId, view, showCompactMode }: SetViewSettingsCompactModeProps): Action<State> =>
	({ setState, getState }) => {
		const currentSettings = getState();
		storage.setViewSettingsCompactMode({
			boardId,
			showCompactMode,
			view: view || currentSettings.view || undefined,
		});
		setState({
			...currentSettings,
			showCompactMode,
		});
	};

export const setViewSettingsCardDetails =
	({ boardId, view, cardDetailsState }: SetViewSettingsCardDetailsProps): Action<State> =>
	({ setState, getState }) => {
		const currentSettings = getState();
		storage.setViewSettingsCardDetails({
			boardId,
			cardDetailsState,
			view: view || currentSettings.view || undefined,
		});
		setState({
			...currentSettings,
			showEpics: cardDetailsState.SHOW_EPICS,
			showVersions: cardDetailsState.SHOW_VERSIONS,
			showStatuses: cardDetailsState.SHOW_STATUSES,
			showIssueLinksStats: cardDetailsState.SHOW_ISSUE_LINKS_STATS,
			showDaysInColumn: cardDetailsState.SHOW_DAYS_IN_COLUMN,
			showIssueType: cardDetailsState.SHOW_ISSUE_TYPE,
			showIssueKey: cardDetailsState.SHOW_ISSUE_KEY,
			showEstimate: cardDetailsState.SHOW_ESTIMATE,
			showPriority: cardDetailsState.SHOW_PRIORITY,
			showDevelopment: cardDetailsState.SHOW_DEVELOPMENT,
			showAssignee: cardDetailsState.SHOW_ASSIGNEE,
			showDueDate: cardDetailsState.SHOW_DUE_DATE,
			showLabels: cardDetailsState.SHOW_LABELS,
			showCardCovers: cardDetailsState.SHOW_CARD_COVERS,
		});
	};

export const setViewSettingsCardExtraFields =
	({ boardId, view, cardExtraFieldsState }: SetViewSettingsCardExtraFieldsProps): Action<State> =>
	({ setState, getState }) => {
		const currentSettings = getState();
		storage.setViewSettingsCardExtraFields({
			boardId,
			cardExtraFieldsState,
			view: view || currentSettings.view || undefined,
		});
		setState({
			...currentSettings,
			cardExtraFields: cardExtraFieldsState,
		});
	};

export const setViewSettingsShowCardCovers =
	({ boardId, view, showCardCovers }: SetViewSettingsShowCardCoversProps): Action<State> =>
	({ setState, getState }) => {
		const currentSettings = getState();
		const newState: State = {
			...currentSettings,
			showCardCovers,
		};
		storage.setViewSettings({
			boardId,
			state: newState,
			view: view || currentSettings.view || undefined,
		});
		setState(newState);
	};

// Toggles a view setting's state - updating both localStorage state and sweet-state.
export const toggleViewSetting =
	({ boardId, view, setting }: ToggleViewSettingProps): Action<State> =>
	({ setState, getState }) => {
		const currentSettings = getState();
		const set = (newState: State) => {
			setState(newState);
			storage.setViewSettings({
				boardId,
				state: newState,
				view: view || currentSettings.view || undefined,
			});
		};
		switch (setting) {
			case ViewSetting.VERSION_PANEL:
				set({
					...currentSettings,
					showVersionPanel: !currentSettings.showVersionPanel,
				});
				break;
			case ViewSetting.EPIC_PANEL:
				set({
					...currentSettings,
					showEpicPanel: !currentSettings.showEpicPanel,
				});
				break;
			case ViewSetting.QUICK_FILTERS:
				set({
					...currentSettings,
					showQuickFilters: !currentSettings.showQuickFilters,
				});
				break;

			case ViewSetting.COMPACT_MODE:
				set({
					...currentSettings,
					showCompactMode: !currentSettings.showCompactMode,
				});
				break;
			case ViewSetting.SHOW_EPICS:
				set({
					...currentSettings,
					showEpics: !currentSettings.showEpics,
				});
				break;
			case ViewSetting.SHOW_STATUSES:
				set({
					...currentSettings,
					showStatuses: !currentSettings.showStatuses,
				});
				break;
			case ViewSetting.SHOW_VERSIONS:
				set({
					...currentSettings,
					showVersions: !currentSettings.showVersions,
				});
				break;
			case ViewSetting.SHOW_UNSCHEDULED_COLUMN:
				set({
					...currentSettings,
					showUnscheduledColumn: !currentSettings.showUnscheduledColumn,
				});
				break;
			case ViewSetting.SHOW_ISSUE_KEY:
				set({
					...currentSettings,
					showIssueKey: !currentSettings.showIssueKey,
				});
				break;
			case ViewSetting.SHOW_ISSUE_TYPE:
				set({
					...currentSettings,
					showIssueType: !currentSettings.showIssueType,
				});
				break;
			case ViewSetting.SHOW_PRIORITY:
				set({
					...currentSettings,
					showPriority: !currentSettings.showPriority,
				});
				break;
			case ViewSetting.SHOW_DEVELOPMENT:
				set({
					...currentSettings,
					showDevelopment: !currentSettings.showDevelopment,
				});
				break;
			case ViewSetting.SHOW_ASSIGNEE:
				set({
					...currentSettings,
					showAssignee: !currentSettings.showAssignee,
				});
				break;
			case ViewSetting.SHOW_ESTIMATE:
				set({
					...currentSettings,
					showEstimate: !currentSettings.showEstimate,
				});
				break;
			case ViewSetting.SHOW_DAYS_IN_COLUMN:
				set({
					...currentSettings,
					showDaysInColumn: !currentSettings.showDaysInColumn,
				});
				break;
			case ViewSetting.SHOW_DUE_DATE:
				set({
					...currentSettings,
					showDueDate: !currentSettings.showDueDate,
				});
				break;
			case ViewSetting.SHOW_LABELS:
				set({
					...currentSettings,
					showLabels: !currentSettings.showLabels,
				});
				break;
			case ViewSetting.SHOW_CARD_COVERS:
				set({
					...currentSettings,
					showCardCovers: !currentSettings.showCardCovers,
				});
				break;
			case ViewSetting.SHOW_OFFTRACK_DEPENDENCY_LINES:
				set({
					...currentSettings,
					showOfftrackDependencyLines: !currentSettings.showOfftrackDependencyLines,
				});
				break;
			default:
				break;
		}
	};

export const toggleShowEpics =
	({ boardId, view }: ConfigureViewSettingsProps): Action<State> =>
	({ setState, getState }) => {
		const currentSettings = getState();
		storage.setShowEpics({
			boardId,
			showEpics: !currentSettings.showEpics,
			view: view || currentSettings.view || undefined,
		});
		setState({
			...currentSettings,
			showEpics: !currentSettings.showEpics,
		});
	};

export const toggleShowVersions =
	({ boardId, view }: ConfigureViewSettingsProps): Action<State> =>
	({ setState, getState }) => {
		const currentSettings = getState();
		storage.setShowVersions({
			boardId,
			showVersions: !currentSettings.showVersions,
			view: view || currentSettings.view || undefined,
		});
		setState({
			...currentSettings,
			showVersions: !currentSettings.showVersions,
		});
	};

export const toggleShowStatuses =
	({ boardId, view }: ConfigureViewSettingsProps): Action<State> =>
	({ setState, getState }) => {
		const currentSettings = getState();
		storage.setShowStatuses({
			boardId,
			showStatuses: !currentSettings.showStatuses,
			view: view || currentSettings.view || undefined,
		});
		setState({
			...currentSettings,
			showStatuses: !currentSettings.showStatuses,
		});
	};

export const toggleShowLinkedIssuesStats =
	({ boardId, view }: ConfigureViewSettingsProps): Action<State> =>
	({ setState, getState }) => {
		const currentSettings = getState();
		storage.setShowIssueLinksStats({
			boardId,
			showIssueLinksStats: !currentSettings.showIssueLinksStats,
			view: view || currentSettings.view || undefined,
		});
		setState({
			...currentSettings,
			showIssueLinksStats: !currentSettings.showIssueLinksStats,
		});
	};

export const setShowUnscheduledColumn =
	(boardId: number, isVisible: boolean): Action<State> =>
	({ setState, getState }) => {
		const currentSettings = getState();
		storage.setShowUnscheduledColumn({ boardId, showUnscheduledColumn: isVisible });
		setState({
			...currentSettings,
			showUnscheduledColumn: isVisible,
		});
	};

export const toggleShowDaysInColumn =
	({ boardId, view }: ConfigureViewSettingsProps): Action<State> =>
	({ setState, getState }) => {
		const currentSettings = getState();
		storage.setShowDaysInColumn({
			boardId,
			showDaysInColumn: !currentSettings.showDaysInColumn,
			view: view || currentSettings.view || undefined,
		});
		setState({
			...currentSettings,
			showDaysInColumn: !currentSettings.showDaysInColumn,
		});
	};

export const toggleShowDueDate =
	({ boardId, view }: ConfigureViewSettingsProps): Action<State> =>
	({ setState, getState }) => {
		const currentSettings = getState();
		storage.setShowDueDate({
			boardId,
			showDueDate: !currentSettings.showDueDate,
			view: view || currentSettings.view || undefined,
		});
		setState({
			...currentSettings,
			showDueDate: !currentSettings.showDueDate,
		});
	};

export const toggleShowLabels =
	({ boardId, view }: ConfigureViewSettingsProps): Action<State> =>
	({ setState, getState }) => {
		const currentSettings = getState();
		storage.setShowLabels({
			boardId,
			showLabels: !currentSettings.showLabels,
			view: view || currentSettings.view || undefined,
		});
		setState({
			...currentSettings,
			showLabels: !currentSettings.showLabels,
		});
	};

export const toggleExtraField =
	({ boardId, view, fieldId }: ConfigureViewSettingsProps & { fieldId: string }): Action<State> =>
	({ setState, getState }) => {
		const currentSettings = getState();
		const currentExtraFields = currentSettings.cardExtraFields;
		storage.setExtraFields({
			boardId,
			cardExtraFields: {
				...currentExtraFields,
				[fieldId]: !currentExtraFields[fieldId],
			},
			view: view || currentSettings.view || undefined,
		});
		setState({
			...currentSettings,
			cardExtraFields: {
				...currentExtraFields,
				[fieldId]: !currentExtraFields[fieldId],
			},
		});
	};

export const setShowOfftrackDependencyLines =
	(boardId: number, isVisible: boolean): Action<State> =>
	({ setState, getState }) => {
		const currentSettings = getState();
		storage.setShowOfftrackDependencyLines({ boardId, showOfftrackDependencyLines: isVisible });
		setState({
			...currentSettings,
			showOfftrackDependencyLines: isVisible,
		});
	};

export const setIssueIdsToShowDependencies =
	(boardId: number, issueIds: IssueId[]): Action<State> =>
	({ setState, getState }) => {
		const currentSettings = getState();
		storage.setIssueIdsToShowDependencies({ boardId, issueIds });
		setState({
			...currentSettings,
			issueIdsToShowDependencies: issueIds,
		});
	};
