import queryString from 'query-string';
import type { GetViewSettingsProps } from '@atlassian/jira-software-view-settings/src/common/types/settings.tsx';
import type { State as ViewSettingsState } from '@atlassian/jira-software-view-settings/src/common/types/state.tsx';
import { isViewSettingsPanelAndTailoredViewExperimentEnabled } from '@atlassian/jira-software-view-settings/src/common/utils';
import type {
	ErrorResponse,
	ParsedQueryParameters,
	BoardLocationResult,
	BoardQueryParameters,
	CachedFilters,
} from './types';

const SOFTWARE_BOARD_REGEX = /\/jira\/software\/c\/?projects\/\w+\/boards\/(\d+)\/?(\?.*)?$/;
const USER_LOCATED_BOARD_REGEX = /\/jira\/people\/[^/]+\/boards\/(\d+)\/?(\?.*)?$/;

// people/project match needs to consider `...:...` ids
const SOFTWARE_BACKLOG_REGEX =
	/\/jira\/(?:software\/c\/)?(?:projects|people)\/[^/]+\/boards\/(\d+)\/backlog\/?(\?.*)?$/;

const parsePath = (pathname: string, pattern: RegExp): { boardId: number } | null => {
	const match = pattern.exec(pathname);
	if (!match) {
		return null;
	}
	const boardId = match[1];
	return { boardId: Number(boardId) };
};

// Safety check to guarantee that whatever we add here is an array
export const convertQuickFiltersToString = (
	queryParameters: BoardQueryParameters | undefined,
): string | undefined => {
	// Terminate early for the null/undefined cases
	if (!queryParameters?.quickFilter) {
		return undefined; // This is safe to return as it means the query string will just be excluded
	}

	if (!Array.isArray(queryParameters.quickFilter)) {
		// If for whatever reason an object gets in here
		if (typeof queryParameters.quickFilter === 'object') {
			// Fail gracefully
			return undefined;
		}

		// Ensure that the value is a number
		if (Number.isNaN(Number(queryParameters.quickFilter))) return undefined;

		// this isn't an array. It's probably a string or number which is fine to return as is.
		return `${queryParameters.quickFilter}`;
	}

	// Array passed in is the common case
	// If it is empty, don't include it at all.
	if (queryParameters.quickFilter.length === 0) {
		return undefined;
	}

	return queryParameters.quickFilter.filter((val) => Number.isInteger(Number(val))).join(',');
};

export const getCachedQuickFilters = (feature: 'board' | 'backlog', boardId: string | number) => {
	const cachedFilters = localStorage.getItem(`__storejs_software_${feature}-filters.${boardId}`);
	if (!cachedFilters) {
		return undefined;
	}

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const { CUSTOM_FILTER: cachedQuickFilters } = JSON.parse(cachedFilters) as CachedFilters;
	return cachedQuickFilters && cachedQuickFilters.length > 0
		? cachedQuickFilters.join(',')
		: undefined;
};

export const parseBoardLocation = ({ pathname, search }: Location): BoardLocationResult | null => {
	const parsedPath =
		parsePath(pathname, SOFTWARE_BOARD_REGEX) ?? parsePath(pathname, USER_LOCATED_BOARD_REGEX);
	if (!parsedPath) {
		return null;
	}

	const queryStringParameters = queryString.parse(search) ?? {};

	// Early termination if the quick filter isnt a part of the query string
	if (!queryStringParameters.quickFilter) {
		return {
			...parsedPath,
		};
	}

	// Guarantee that the quick filter will be returned as an array. This helps with typing further upstream
	const quickFilterArray = Array.isArray(queryStringParameters.quickFilter)
		? queryStringParameters.quickFilter
		: [queryStringParameters.quickFilter];

	return {
		...parsedPath,
		queryParameters: {
			quickFilter: quickFilterArray,
		},
	};
};

export const parseBacklogLocation = ({
	pathname,
	search,
}: Location): {
	boardId: number;
	queryParameters: ParsedQueryParameters;
} | null => {
	const parsedPath = parsePath(pathname, SOFTWARE_BACKLOG_REGEX);
	if (!parsedPath) {
		return null;
	}

	return {
		...parsedPath,
		queryParameters: queryString.parse(search) ?? {},
	};
};

const isErrorMessagesResponse = (json: unknown): json is ErrorResponse =>
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	(json as ErrorResponse)?.errorMessages instanceof Array;

export const extractErrorMessage = (json: unknown): string | undefined => {
	if (isErrorMessagesResponse(json)) {
		const { errorMessages } = json;
		return errorMessages.length ? errorMessages.join(', ') : undefined;
	}
	return undefined;
};

/* Copied from jira/src/packages/software/view-settings/src/services/local-storage/index.tsx
 to reduce bundle size by using native localStorage */

const VIEW_SETTINGS = 'view-settings';
const VIEW_SETTINGS_LOCAL_STORAGE = 'jira-software-view-settings';
export const View = {
	BACKLOG: 'jsw-backlog',
	BOARD: 'jsw-board',
	INCREMENT_PLANNING_BOARD: 'increment-planning-board',
	JSM_BOARD: 'jsm-board',
};

export const getPrefix = (view?: (typeof View)[keyof typeof View]): string => {
	if (view && view === View.INCREMENT_PLANNING_BOARD) {
		return `${VIEW_SETTINGS}-${View.INCREMENT_PLANNING_BOARD}`;
	}
	if (isViewSettingsPanelAndTailoredViewExperimentEnabled()) {
		switch (view) {
			case View.INCREMENT_PLANNING_BOARD:
				return `${VIEW_SETTINGS}-${View.INCREMENT_PLANNING_BOARD}`;
			case View.BACKLOG:
				return `${VIEW_SETTINGS}-${View.BACKLOG}`;
			case View.BOARD:
				return `${VIEW_SETTINGS}-${View.BOARD}`;
			case View.JSM_BOARD:
				return `${VIEW_SETTINGS}-${View.JSM_BOARD}`;
			default:
				return VIEW_SETTINGS;
		}
	}
	return VIEW_SETTINGS;
};

export const getViewSettings = ({
	boardId,
	view,
}: GetViewSettingsProps): ViewSettingsState | null => {
	/**
	 * This usage is excluded via package exclusion in jira/ratcheting-rules.tsx
	 * Because it is only a READ Operation, and reads are not blocked in compliant APIs.
	 * This read will be tracked via a compliant API read in following code
	 * - Initializing view-settings panel on board: jira/src/packages/software/view-settings/src/ui/index.tsx
	 * - rendering cards on the board (Implementatin In Progress https://hello.jira.atlassian.cloud/browse/PNDR-1926)
	 * More context in this discussion: https://atlassian.slack.com/archives/C02785CLSTW/p1722822320190909
	 */
	const viewSettingStorageValue =
		localStorage.getItem(
			`__storejs_${VIEW_SETTINGS_LOCAL_STORAGE}_${getPrefix(view)}-${boardId}`,
		) ||
		// return legacy view settings if prefixed storage doesn't exist
		localStorage.getItem(`__storejs_${VIEW_SETTINGS_LOCAL_STORAGE}_${VIEW_SETTINGS}-${boardId}`);
	return JSON.parse(viewSettingStorageValue ?? 'null');
};
