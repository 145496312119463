import React, { useEffect } from 'react';
import isNil from 'lodash/isNil';
import Image from '@atlaskit/image';
import Link from '@atlaskit/link';
import { ButtonItem, MenuGroup, Section } from '@atlaskit/menu';
import { Box, Inline, Text, xcss } from '@atlaskit/primitives';
import type { FormatOptionLabelMeta } from '@atlaskit/select';
import { fg } from '@atlassian/jira-feature-gating';
import HelpPanelButton from '@atlassian/jira-help-panel-button';
import { useIntl } from '@atlassian/jira-intl';
import { useActiveRightSidebarState } from '@atlassian/jira-layout-controller/src/controllers/layout-controller/consumers/right-sidebar-controller';
import {
	DOC_LINKS,
	IPH_IDS,
} from '@atlassian/jira-portfolio-3-plan-increment-common/src/common/constants';
import commonMessages from '../../../../common/messages';
import {
	Action,
	ActionSubject,
	ToggleKey,
	IncrementPlanningAnalyticsKey,
} from '../../../../common/types/analytics.tsx';
import { ExpandableState } from '../../../../common/types/constant.tsx';
import type { IncrementPlanningBoardViewProps } from '../../../../common/types/menu.tsx';
import type { IssueId } from '../../../../common/types/state.tsx';
import { ViewSettingsSelect } from '../../../../common/ui/view-settings-select';
import { ViewSettingsToggle } from '../../../../common/ui/view-settings-toggle';
import { useViewSettings } from '../../../../controllers';
import messages from './messages';

type IssueOption = {
	value: IssueId;
	label: string;
	iconUrl?: string;
};

export const IncrementPlanningBoardMenu = ({
	viewAs,
	boardId,
	isSwimlanesEnabled = false,
	onHandleChange,
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onSwimlaneExpandAll = () => {},
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onSwimlaneCollapseAll = () => {},
	dependencyIssueOptions = {},
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onUpdate = () => {},
}: IncrementPlanningBoardViewProps) => {
	const { formatMessage } = useIntl();
	const [state, actions] = useViewSettings();
	const rightPanelState = useActiveRightSidebarState();

	useEffect(() => {
		if (
			!isNil(rightPanelState?.panelId) &&
			fg('dependency_visualisation_program_board_fe_and_be')
		) {
			onUpdate();
		}
	}, [rightPanelState?.panelId, onUpdate]);

	const {
		setShowUnscheduledColumn,
		setShowOfftrackDependencyLines,
		setIssueIdsToShowDependencies,
	} = actions;

	const onFormatOptionLabel = (
		option: IssueOption,
		formatOptionLabelMeta: FormatOptionLabelMeta<IssueOption>,
	) => {
		return (
			<Inline space="space.050" alignBlock="start">
				{option.iconUrl && (
					<Box xcss={formatOptionLabelMeta.context === 'menu' ? menuIconStyles : valueIconStyles}>
						<Image src={option.iconUrl} alt="" />
					</Box>
				)}
				<Box>{option.label}</Box>
			</Inline>
		);
	};

	let issueOptions: IssueOption[] = [];
	const defaultIssueOptions: IssueOption[] = [];
	if (fg('dependency_visualisation_program_board_fe_and_be')) {
		issueOptions = Object.values(dependencyIssueOptions).map((issue) => ({
			value: issue.id,
			label: `${issue.key} ${issue.summary}`,
			iconUrl: issue.typeUrl,
		}));
		if (Array.isArray(state.issueIdsToShowDependencies)) {
			state.issueIdsToShowDependencies.forEach((issueId) => {
				const issue = dependencyIssueOptions[issueId];
				if (issue) {
					defaultIssueOptions.push({
						value: issue.id,
						label: `${issue.key} ${issue.summary}`,
						iconUrl: issue.typeUrl,
					});
				}
			});
		}
	}

	return (
		<MenuGroup data-testId="software-view-settings.ui.view-settings-menu.menu-increment-planning-board">
			<Section>
				<ViewSettingsToggle
					testId="software-view-settings.ui.view-settings-menu.views.increment-planning-board.show-unscheduled-column-toggle"
					title={formatMessage(commonMessages.showUnscheduledWork)}
					onChange={() => {
						setShowUnscheduledColumn(boardId, !state.showUnscheduledColumn);
						onHandleChange(
							ActionSubject.TOGGLE,
							{
								key: IncrementPlanningAnalyticsKey.UNSCHEDULED_COLUMN_TOGGLE,
								state: !state.showUnscheduledColumn,
							},
							Action.CLICKED,
						);
					}}
					isChecked={state.showUnscheduledColumn}
					viewAs={viewAs}
				/>
			</Section>
			{isSwimlanesEnabled && (
				<Section title={formatMessage(commonMessages.swimlanes)} hasSeparator>
					<ButtonItem
						data-testid="software-view-settings.ui.view-settings-menu.views.increment-planning-board.expand-all"
						onClick={() => {
							onSwimlaneExpandAll();
							onHandleChange(
								ActionSubject.BUTTON,
								{
									key: ToggleKey.SWIMLANE_EXPAND_ALL,
									state: ExpandableState.EXPANDED,
								},
								Action.CLICKED,
							);
						}}
					>
						{formatMessage(commonMessages.expandAll)}
					</ButtonItem>
					<ButtonItem
						data-testid="software-view-settings.ui.view-settings-menu.views.increment-planning-board.collapse-all"
						onClick={() => {
							onSwimlaneCollapseAll();
							onHandleChange(
								ActionSubject.BUTTON,
								{
									key: ToggleKey.SWIMLANE_COLLAPSE_ALL,
									state: ExpandableState.COLLAPSED,
								},
								Action.CLICKED,
							);
						}}
					>
						{formatMessage(commonMessages.collapseAll)}
					</ButtonItem>
				</Section>
			)}
			{fg('dependency_visualisation_program_board_fe_and_be') && (
				<Section title={formatMessage(messages.dependencyLinesSection)} hasSeparator>
					<ViewSettingsToggle
						testId="software-view-settings.ui.view-settings-menu.views.increment-planning-board.offtrack-dependencies-toggle"
						title={formatMessage(messages.showOfftrackDependencyLines)}
						onChange={() => {
							setShowOfftrackDependencyLines(boardId, !state.showOfftrackDependencyLines);
							onHandleChange(
								ActionSubject.TOGGLE,
								{
									key: IncrementPlanningAnalyticsKey.SHOW_OFFTRACK_DEPENDENCY_LINES,
									state: !state.showOfftrackDependencyLines,
								},
								Action.CLICKED,
							);
						}}
						isChecked={state.showOfftrackDependencyLines}
						viewAs={viewAs}
						footer={
							<HelpPanelButton
								articleId={IPH_IDS.OFFTRACK_DEPENDENCIES}
								appearance="link"
								spacing="none"
								fallbackComponent={
									<Link href={DOC_LINKS.OFFTRACK_DEPENDENCIES} target="_blank">
										{formatMessage(messages.offtrackDependenciesHelpLink)}
									</Link>
								}
							>
								<Text color="color.text.accent.blue">
									{formatMessage(messages.offtrackDependenciesHelpLink)}
								</Text>
							</HelpPanelButton>
						}
					/>
					<Box xcss={selectWrapper}>
						<ViewSettingsSelect
							title={formatMessage(messages.showDependenciesOnIssues)}
							testId="software-view-settings.ui.view-settings-menu.views.increment-planning-board.dependency-lines-select"
							isMulti
							isSearchable
							options={issueOptions}
							formatOptionLabel={onFormatOptionLabel}
							viewAs={viewAs}
							defaultValue={defaultIssueOptions}
							placeholder={formatMessage(messages.chooseIssue)}
							onChange={(selectedOptions) => {
								let selectedIssueIds = [];
								if (Array.isArray(selectedOptions)) {
									selectedIssueIds = selectedOptions.map((option) => option.value);
								} else if (selectedOptions && 'value' in selectedOptions) {
									selectedIssueIds = selectedOptions ? [selectedOptions.value] : [];
								}
								setIssueIdsToShowDependencies(boardId, selectedIssueIds);
								onHandleChange(
									ActionSubject.SELECT,
									{
										key: IncrementPlanningAnalyticsKey.ISSUE_IDS_TO_SHOW_DEPENDENCIES,
										count: selectedIssueIds.length,
									},
									Action.CHANGED,
								);
							}}
						/>
					</Box>
				</Section>
			)}
		</MenuGroup>
	);
};

const menuIconStyles = xcss({
	marginTop: 'space.025',
	width: '16px',
	minWidth: '16px',
	height: '16px',
	minHeight: '16px',
});

const valueIconStyles = xcss({
	width: '16px',
	minWidth: '16px',
	height: '16px',
	minHeight: '16px',
});
const selectWrapper = xcss({
	width: '450px',
});
