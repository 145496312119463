import React, {
	type AllHTMLAttributes,
	// eslint-disable-next-line jira/restricted/react
	PureComponent,
	type ReactNode,
	type MouseEvent,
	type FocusEvent,
	type ComponentType,
	type RefObject,
} from 'react';
import { styled } from '@compiled/react';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import noop from 'lodash/noop';
import Button from '@atlaskit/button';
import DropdownMenu, {
	type OnOpenChangeArgs,
	type CustomTriggerProps,
} from '@atlaskit/dropdown-menu';
import ShowMoreHorizontalIcon from '@atlaskit/icon/core/show-more-horizontal';
import MoreIcon from '@atlaskit/icon/glyph/more';
import { token } from '@atlaskit/tokens';
import ToolTip from '@atlaskit/tooltip';
import { categoryIdForStatusCategory } from '@atlassian/jira-common-constants/src/status-categories';
import { fg } from '@atlassian/jira-feature-gating';
import { type IntlShapeV2 as IntlShape, injectIntlV2 as injectIntl } from '@atlassian/jira-intl';
import { IssueStatusFieldReadonly } from '@atlassian/jira-issue-field-status/async';
import type { ExternalAction } from '@atlassian/jira-issue-view-store/src/actions/external-actions';
import {
	Card as JiraCard,
	CardChildIssues,
	CardContextMenu,
	CardDevStatus,
	CardDueDate,
	CardEstimate,
	CardLabels,
	CardLozenge,
	CardPriority,
	CardDaysInColumn,
	type RenderSummary,
} from '@atlassian/jira-platform-card';
import {
	ContextualAnalyticsData,
	SCREEN,
	AnalyticsEventToProps,
} from '@atlassian/jira-product-analytics-bridge';
import type { StatusDetails } from '@atlassian/jira-shared-types';
import { CONTEXT_MENU_APPEARANCE } from '@atlassian/jira-software-context-menu';
import { BoardView } from '../../../common/constants';
import type { CardId, Issue, IssueId, Person, IssueParent, BoardType } from '../../../common/types';
import type { Status } from '../types';
import messages from './messages';

export type Props = {
	isDragging: boolean;
	isLoading: boolean;
	issue: Issue;
	isDone: boolean;
	isSelected: boolean;
	isFlagged: boolean;
	isFlexible?: boolean;
	isDisabled: boolean;
	shouldShowParent?: boolean;
	shouldRenderCardType: boolean;
	persistActions?: boolean;
	parent?: IssueParent | null;
	assignee: Person | null | undefined;
	highlightTextOnCard: string;
	highlightLabelsOnCard: string[];
	menu: ReactNode;
	cover: ReactNode;
	/**
	 * A ref to the Card container that wraps the Card content.
	 * Used for pragmatic-drag-and-drop snapshot previews.
	 */
	cardContainerRef?: RefObject<HTMLElement>;
	/**
	 * A ref to the InteractionLayer that handles pointer events for the Card.
	 */
	cardRef?: RefObject<HTMLButtonElement>;
	dateFormat?: string | null | undefined;
	onClick: (arg1: MouseEvent<HTMLElement>, arg2?: ExternalAction) => void;
	onSizeChange?: () => void; // Notify any parents that are interested in a resize event (CellMeasurerCache),
	onFocus?: (arg1: FocusEvent<HTMLElement>) => void;
	onBlur?: (arg1: FocusEvent<HTMLElement>) => void;
	intl: IntlShape;
	children?: ReactNode;
	renderEstimateField?: () => ReactNode | null;
	renderIssueParentField?: () => ReactNode | null;
	renderSummary?: RenderSummary;
	renderIssueLinksIndicator?: () => ReactNode | null;
	renderAssignee?: () => ReactNode | null;
	isCMPBoard: boolean;
	status: Status | null;
	hasCompactIssueType?: boolean | null;
	selectedCardIds: number[];
	CustomContextMenu?: ComponentType<{
		appearance: typeof CONTEXT_MENU_APPEARANCE;
		children: ReactNode;
		selectedCardIds: CardId[];
		issueId: IssueId;
		issueKey: string;
	}>;
	// menu component including trigger as well
	renderCustomMenu?: () => ReactNode | null;
	renderIssueLinksStats?: () => ReactNode | null;
	showDaysInColumn?: boolean;
	showIssueKey?: boolean;
	showEstimate?: boolean;
	showPriority?: boolean;
	showDevelopment?: boolean;
	showAssignee?: boolean;
	showDueDate?: boolean;
	showLabels?: boolean;
	showCardExtraFields?: Record<string, boolean> | null;
	boardType?: BoardType;
};

type State = {
	shouldMenuOpen: boolean;
	shouldRenderMenu: boolean;
	isMenuOpen: boolean;
};

/**
 * There is an inconsistency between @atlaskit/tooltip::Tooltip::tag type and
 * the @compiled/react div type. This inconsistency is on the ref type that
 * compiled sets vs @atlaskit's type and caused only because @compiled/react
 * supports `string` legacy refs for class based usage.
 *
 * This cast is safe, since that's the only difference.
 */
type TooltipTagType = ComponentType<
	AllHTMLAttributes<HTMLDivElement> & {
		ref: React.Ref<HTMLElement>;
	}
>;

const DropdownMenuWithAnalytics = AnalyticsEventToProps('dropdown-menu', {
	onOpenChange: 'openChanged',
})(DropdownMenu);

// eslint-disable-next-line jira/react/no-class-components
class JiraCardContents extends PureComponent<Props, State> {
	static defaultProps = {
		isDragging: false,
		isLoading: false,
		isDone: false,
		isSelected: false,
		isFlagged: false,
		isFlexible: false,
		isDisabled: false,
		hasTransitioned: false,
		shouldShowParent: false,
		shouldRenderCardType: true,
		persistActions: false,
		parent: null,
		assignee: null,
		highlightTextOnCard: '',
		highlightLabelsOnCard: [],
		labels: null,
		menu: null,
		selectedCardIds: [],
		CustomContextMenu: null,
		renderCustomMenu: null,
		cover: null,
		color: null,
		customFields: null,
		children: null,
		dateFormat: null,
		renderIssueParentField: null,
		renderSummary: null,
		renderIssueLinksIndicator: null,
		renderAssignee: null,
		renderEstimate: null,
		isCMPBoard: false,
		status: null,
		onSizeChange: noop,
		onEnterPress: noop,
		showDaysInColumn: true,
		showDueDate: true,
		showIssueKey: true,
		showEstimate: true,
		showPriority: true,
		showDevelopment: true,
		showAssignee: true,
		showLabels: true,
		showCardExtraFields: null,
		shouldShowLozengeForOptimisticParent: false,
	};

	state = {
		shouldMenuOpen: true,
		shouldRenderMenu: false,
		isMenuOpen: false,
	};

	componentDidMount() {
		if (__SERVER__) {
			return;
		}

		this.mounted = true;

		// Note whenever we drag or modify a list we'll fire this. The plus side of having it here is
		// when we render a proper card instead of the scrolling placeholder we'll store the real height in the
		// cache for our future placeholders
		(this.props.onSizeChange ?? noop)();
	}

	componentDidUpdate() {
		if (__SERVER__) {
			return;
		}

		// Notify listeners of any update to this component. The CellCacheMeasurer remeasure
		// is faster than doing a deepEquals here.
		// NOTE: This actually gets procced via onFocus for unrelated cards due to some re-render shinanigans.
		this.notifyListenersOfSizeChange();
	}

	componentWillUnmount() {
		this.mounted = false;
		if (this.requestAnimationFrameId != null) {
			cancelAnimationFrame(this.requestAnimationFrameId);
		}
	}

	notifyListenersOfSizeChange() {
		(this.props.onSizeChange ?? noop)();
	}

	mounted = false;

	requestAnimationFrameId: number | null = null;

	setShouldRenderMenu = () => {
		this.setState({
			shouldRenderMenu: true,
		});
	};

	onFocus = (event: FocusEvent<HTMLElement>) => {
		this.props.onFocus && this.props.onFocus(event);
		this.setShouldRenderMenu();
	};

	renderMenu = () => {
		const {
			menu,
			issue: { summary },
			intl: { formatMessage },
			renderCustomMenu,
		} = this.props;
		const { shouldMenuOpen } = this.state;

		if (!isNil(renderCustomMenu)) {
			return renderCustomMenu();
		}
		if (isNil(menu) || !shouldMenuOpen) {
			return null;
		}

		return (
			<ContextualAnalyticsData sourceType={SCREEN}>
				<DropdownMenuWithAnalytics
					trigger={({ triggerRef, ...props }: CustomTriggerProps) => (
						<Button
							{...props}
							ref={triggerRef}
							iconBefore={
								fg('tnk-1614-visual-refresh-boardkit-icons') ? (
									<ShowMoreHorizontalIcon
										spacing="spacious"
										label="Menu"
										LEGACY_fallbackIcon={MoreIcon}
										color={token('color.icon')}
									/>
								) : (
									<MoreIcon label="Menu" />
								)
							}
							aria-label={formatMessage(messages.actionsMenuTriggerLabel, {
								summary,
							})}
						/>
					)}
					spacing="compact"
					placement="bottom-end"
					onOpenChange={({ isOpen }: OnOpenChangeArgs) => {
						this.setState({ isMenuOpen: isOpen });
					}}
				>
					{menu}
				</DropdownMenuWithAnalytics>
			</ContextualAnalyticsData>
		);
	};

	renderDevStatus() {
		const {
			issue: { id, devStatusField },
			showDevelopment,
		} = this.props;

		if (!showDevelopment) return null;

		return devStatusField ? (
			<CardDevStatus
				issueId={id}
				key={`dev-status-${devStatusField.activity}-${devStatusField.count}`}
				type={devStatusField.activity}
				deploymentDetails={devStatusField.deploymentDetails}
			/>
		) : null;
	}

	renderIssueParent() {
		const { shouldShowParent, parent, renderIssueParentField } = this.props;

		if (!shouldShowParent || !parent) return null;

		if (!isNil(renderIssueParentField)) return renderIssueParentField();

		return (
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			<ToolTip tag={TooltipContainer as TooltipTagType} content={parent.summary}>
				<CardLozenge color={parent.color}>{parent.summary}</CardLozenge>
			</ToolTip>
		);
	}

	renderChildIssuesMetadata() {
		const {
			issue: { numCompleteChildren, numTotalChildren },
		} = this.props;

		if (numTotalChildren == null || numTotalChildren === 0) return null;

		return (
			<CardChildIssues
				key={`card-children-${numCompleteChildren}-${numTotalChildren}`}
				complete={numCompleteChildren}
				total={numTotalChildren}
			/>
		);
	}

	renderLabels() {
		const {
			issue: { labels },
			highlightLabelsOnCard,
			isCMPBoard,
			showLabels,
		} = this.props;

		const shouldShowLabels = showLabels && !isCMPBoard;

		return (
			labels &&
			shouldShowLabels &&
			!isEmpty(labels) && (
				<CardLabels labels={labels} highlight={highlightLabelsOnCard} isCMPBoard={isCMPBoard} />
			)
		);
	}

	renderEstimate() {
		const {
			issue: { estimate, estimateUnit },
			showEstimate,
		} = this.props;

		if (!showEstimate) return null;

		const { renderEstimateField } = this.props;
		if (!isNil(renderEstimateField)) return renderEstimateField();

		if (estimate == null) {
			return null;
		}

		return (
			<CardEstimate
				key={`card-estimate-${estimate}`}
				estimate={estimate}
				units={estimateUnit ?? messages.storyPointsUnitsPluralisedLabel}
			/>
		);
	}

	renderPriority() {
		const {
			issue: { priority },
			showPriority,
		} = this.props;

		if (!showPriority) return null;

		return (
			priority && (
				<CardPriority
					key={`card-priority-${priority.name}`}
					name={priority.name}
					uri={priority.iconUrl}
				/>
			)
		);
	}

	renderDueDate() {
		const { issue, isDone, dateFormat, showDueDate } = this.props;

		return showDueDate && !isNil(issue.dueDate) ? (
			<CardDueDate dateFormat={dateFormat} dueDate={issue.dueDate} isCompleted={isDone} />
		) : null;
	}

	renderDaysInColumn() {
		const {
			issue: { daysInColumn },
			showDaysInColumn,
		} = this.props;

		return daysInColumn != null && daysInColumn > 1 && Boolean(showDaysInColumn) ? (
			<CardDaysInColumn days={daysInColumn} />
		) : null;
	}

	renderIcons() {
		const { boardType, renderIssueLinksIndicator } = this.props;
		const isIPBoard = boardType === BoardView.INCREMENT_PLANNING_BOARD;
		if (isIPBoard) {
			return [
				this.renderEstimate(),
				...(renderIssueLinksIndicator ? [renderIssueLinksIndicator()] : [undefined]),
			];
		}

		return [
			this.renderEstimate(),
			this.renderDevStatus(),
			this.renderChildIssuesMetadata(),
			this.renderDaysInColumn(),
			this.renderPriority(),
		];
	}

	renderStatus() {
		const { status } = this.props;
		if (!status) {
			return null;
		}

		const statusField: StatusDetails = {
			id: status.id.toString(),
			name: status.name,
			statusCategory: {
				id: categoryIdForStatusCategory(status.category),
			},
		};

		return (
			<StatusContainer data-testid="platform-board-kit.ui.card.jira-card-contents.status">
				<ToolTip
					tag={
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						TooltipContainer as TooltipTagType
					}
					content={statusField.name}
				>
					<IssueStatusFieldReadonly value={statusField} />
				</ToolTip>
			</StatusContainer>
		);
	}

	render() {
		const {
			issue,
			assignee,
			isSelected,
			isDragging,
			isFlagged,
			isFlexible,
			menu,
			highlightTextOnCard,
			shouldRenderCardType,
			cover,
			isDone,
			isLoading,
			onBlur,
			cardRef,
			cardContainerRef,
			onSizeChange,
			onClick,
			isDisabled,
			children,
			renderSummary,
			renderAssignee,
			isCMPBoard,
			status,
			hasCompactIssueType,
			selectedCardIds,
			CustomContextMenu,
			renderIssueLinksStats,
			persistActions,
			showIssueKey,
			showCardExtraFields,
			showAssignee,
			boardType,
		} = this.props;

		const isIPBoard = this.props.boardType === BoardView.INCREMENT_PLANNING_BOARD;
		const { shouldRenderMenu, isMenuOpen } = this.state;

		if (boardType === BoardView.JSW_BOARD && !fg('defer_card_meatball_menu_rendering_on_board')) {
			this.setShouldRenderMenu();
		}

		if (
			boardType === BoardView.JSW_BOARD &&
			persistActions &&
			fg('jsw_cmp_card_covers_changeboarding')
		) {
			this.setShouldRenderMenu();
		}

		const onContextMenuChange = (isContextMenuOpen: boolean) =>
			this.setState({ shouldMenuOpen: !isContextMenuOpen });

		const typeUrl = issue.typeUrl !== undefined ? issue.typeUrl : null;

		const contextMenu = shouldRenderMenu ? menu : null;
		const dropdownMenu = shouldRenderMenu ? this.renderMenu() : null;

		const cardContents = (
			<JiraCard
				id={issue.id}
				summary={issue.summary}
				cardKey={showIssueKey ? issue.key : null}
				typeName={shouldRenderCardType ? issue.typeName : null}
				typeUri={shouldRenderCardType ? typeUrl : null}
				avatarUri={showAssignee ? assignee && assignee.avatarUrl : null}
				avatarName={showAssignee ? assignee && assignee.displayName : null}
				isSelected={isSelected || isDragging}
				isFlagged={isFlagged}
				isFlexible={isFlexible}
				isDone={isDone}
				actions={dropdownMenu}
				cover={cover}
				color={issue.cardColor}
				customFields={issue.customFields?.filter(
					(field) => showCardExtraFields?.[field.id] !== false,
				)}
				isLoading={isLoading}
				icons={this.renderIcons()}
				highlightText={[highlightTextOnCard]}
				onClick={onClick}
				onFocus={this.onFocus}
				onBlur={onBlur}
				onMouseEnter={this.setShouldRenderMenu}
				onSizeChange={onSizeChange}
				isDisabled={isDisabled}
				cardRef={cardRef}
				cardContainerRef={cardContainerRef}
				persistActions={persistActions || isMenuOpen}
				renderSummary={renderSummary}
				isCMPBoard={isCMPBoard}
				isIPBoard={isIPBoard}
				status={status}
				hasCompactIssueType={hasCompactIssueType}
				hasScenarioChanges={issue.hasScenarioChanges}
				renderIssueLinksStats={renderIssueLinksStats}
				renderAssignee={showAssignee ? renderAssignee : undefined}
				fixVersions={issue.fixVersions}
			>
				{this.renderIssueParent()}
				{children}
				{status && this.renderStatus()}
				{this.renderLabels()}
				{!isIPBoard && this.renderDueDate()}
			</JiraCard>
		);

		if (!isNil(CustomContextMenu)) {
			return (
				<CustomContextMenu
					appearance={CONTEXT_MENU_APPEARANCE}
					issueId={issue.id}
					issueKey={issue.key}
					selectedCardIds={selectedCardIds}
				>
					{cardContents}
				</CustomContextMenu>
			);
		}

		return (
			<CardContextMenu items={contextMenu} onContextMenuChange={onContextMenuChange}>
				{cardContents}
			</CardContextMenu>
		);
	}
}

export default injectIntl(JiraCardContents);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TooltipContainer = styled.div({
	maxWidth: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StatusContainer = styled.div({
	width: '100%',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'flex-start',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& button': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		border: 'none !important',
	},
});
