import type { ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import { isIncrementPlanningBoardM1Enabled } from '../../feature-flags';
import type { FilterableIssue, MatcherCreator } from '../types';

export const issueProjectMatcherCreator: MatcherCreator<ProjectId[], FilterableIssue> = (
	projectFilter,
) => {
	if (!isIncrementPlanningBoardM1Enabled()) {
		return () => true;
	}

	const projectSet = new Set(projectFilter);
	return ({ projectId }) => !!projectId && projectSet.has(projectId);
};
