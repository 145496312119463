import React, { useMemo, useCallback } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import { Box, xcss } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { CMPCustomFilter, Filters } from '../../../common/types';
import HiddenCheckbox from './hidden-checkbox';
import Label from './label';

type Props = {
	onChange: (filters: Filters, analyticsEvent: UIAnalyticsEvent) => void;
	selectedFilters: Set<string>;
	filter: CMPCustomFilter;
};

const FilterOption = (props: Props) => {
	const { onChange, selectedFilters, filter } = props;
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const isSelected = useMemo(() => selectedFilters.has(filter.id), [selectedFilters, filter]);

	const onClick = useCallback(() => {
		if (isSelected) {
			selectedFilters.delete(filter.id);
		} else {
			selectedFilters.add(filter.id);
		}

		onChange({ CUSTOM_FILTER: Array.from(selectedFilters) }, createAnalyticsEvent({}));
	}, [onChange, filter, isSelected, selectedFilters, createAnalyticsEvent]);

	const id = `checkbox-id-${filter.name}`;

	return (
		<Tooltip
			content={filter.description}
			position="bottom"
			data-testid="software-filters.ui.filter-selection-bar.filter-option.tooltip"
		>
			<Box xcss={maxWidthWrapperStyles}>
				<HiddenCheckbox id={id} checked={isSelected} onChange={onClick} />
				<Button id={id} isSelected={isSelected} component={Label}>
					{filter.name}
				</Button>
			</Box>
		</Tooltip>
	);
};

export default FilterOption;

const maxWidthWrapperStyles = xcss({
	maxWidth: '380px',
});
