import qs from 'query-string';
import { COMPANY_MANAGED_BOARD } from '@atlassian/jira-capabilities/src/constants';
import { setMark, setMeasure } from '@atlassian/jira-common-performance/src/marks.tsx';
import { getTraceId } from '@atlassian/jira-early-script-utils';
import { FetchError } from '@atlassian/jira-early-script-utils/src/types.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import type { MobileRestApiResponse } from '@atlassian/jira-software-board-uif-types';
import { startTTIObserver } from '@atlassian/jira-software-measure-tti/src/services/measure-tti/index.tsx';
import { getEnhancedCapabilityHeader } from '@atlassian/jira-ufo-capability-header';
import { getActiveTraceHttpRequestHeaders } from '@atlassian/react-ufo/experience-trace-id-context';
import { getBoardCachedEntry, type BoardCachedEntry } from '../uif-board-cache';
import {
	parseBoardLocation,
	convertQuickFiltersToString,
	getCachedQuickFilters,
	extractErrorMessage,
	getViewSettings,
	View,
} from '../utils';
import type { BoardQueryParameters } from '../utils/types';

const isAlternateTTIMeasurementEnabled = () => ff('jsw-measure-tti-alternate');

export const FETCH_BOARD_DATA_OPERATION_NAME = 'fetchBoardData';

type CombinedBoardPromiseType = {
	promise: Promise<MobileRestApiResponse>;
	cachedPromise: Promise<BoardCachedEntry | null>;
} | null;

let combinedBoardPromises: CombinedBoardPromiseType = null;
function putBoardEarlyScriptResponse(promises: CombinedBoardPromiseType) {
	combinedBoardPromises = promises;
}

export function takeBoardEarlyScriptResponse(): CombinedBoardPromiseType {
	const promises = combinedBoardPromises;
	combinedBoardPromises = null;
	return promises;
}

export type FetchUIFBoardDataParams = {
	boardId: number | string;
	etag?: string;
	queryParameters?: BoardQueryParameters;
	fields?: string[];
};

const getSkipCardCovers = (boardId: string | number) => {
	const isCardCoversToggled = getViewSettings({
		boardId: Number(boardId),
		view: View.BOARD,
	})?.showCardCovers;
	return !isCardCoversToggled;
};

export function fetchUIFBoardData({ boardId, queryParameters, fields }: FetchUIFBoardDataParams): {
	promise: Promise<MobileRestApiResponse>;
	cachedPromise: Promise<BoardCachedEntry | null>;
} {
	// It is important that there is no async operation before this first
	// return block, otherwise we may create a cycle of this fetch with itself.
	const response = takeBoardEarlyScriptResponse();
	if (response) return response;

	setMark('uif-board-fetch-start');

	const query: {
		moduleKey: string;
		hideCardExtraFields: boolean;
		onlyUseEpicsFromIssues: boolean;
		skipEtag: boolean;
		skipExtraFields: boolean;
		includeHidden?: boolean;
		activeQuickFilters?: string;
		activeSprints?: string[];
		etag?: string;
		fields?: string[];
		operation?: string;
		skipCardCovers?: boolean;
	} = {
		moduleKey: 'agile-mobile-board-service',
		hideCardExtraFields: true,
		onlyUseEpicsFromIssues: true,
		skipEtag: true,
		skipExtraFields: true,
		includeHidden: true,
		activeQuickFilters:
			convertQuickFiltersToString(queryParameters) ?? getCachedQuickFilters('board', boardId),
		fields,
		operation: FETCH_BOARD_DATA_OPERATION_NAME,
		...(fg('jsw_cmp_card_cover_images') ? { skipCardCovers: getSkipCardCovers(boardId) } : {}),
	};

	const queryStr = qs.stringify(query);

	setMark('uif-board-fetch-start-request');

	const boardDataUrl = `/rest/boards/latest/board/${boardId}?${queryStr}`;

	const capabilityHeader = getEnhancedCapabilityHeader(COMPANY_MANAGED_BOARD);
	const headers = {
		...(capabilityHeader ? { 'X-Atlassian-Capability': capabilityHeader } : {}),
	};

	Object.assign(headers, getActiveTraceHttpRequestHeaders(boardDataUrl));
	const cachedPromise = getBoardCachedEntry({ boardId });
	const promise = fetch(boardDataUrl, { headers }).then(async (res) => {
		const json = await res.json().catch(() => null);

		setMark('uif-board-fetch-end');
		setMeasure('uif-board-fetch/request', 'uif-board-fetch-start-request', 'uif-board-fetch-end');
		setMeasure('uif-board-fetch', 'uif-board-fetch-start', 'uif-board-fetch-end');

		if (!res.ok) {
			const traceId = getTraceId(res);
			const message = extractErrorMessage(json);
			if (traceId) {
				throw new FetchError(res.status, message, traceId);
			} else {
				throw new FetchError(res.status, message);
			}
		}
		return json;
	});

	return { cachedPromise, promise };
}

/**
 * Starts the UIF API request for boards.
 */
export function runUIFBoardEarlyScript(location: Location): boolean {
	if (isAlternateTTIMeasurementEnabled()) {
		startTTIObserver();
	}

	const parsedResult = parseBoardLocation(location);
	if (!parsedResult) {
		return false;
	}

	putBoardEarlyScriptResponse(fetchUIFBoardData(parsedResult));

	return true;
}
