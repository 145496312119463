import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { getJswCmpCardCoversExpCohort } from '../../utils';
import {
	getCardSelectionAnalytics,
	hasUserStartedSelecting,
	hasUserUnselectedAll,
	shouldFlowBeEnded,
} from '../analytics/utils';
import type { ContextMenuAction, FireOpenMenuAnalyticsEventProps } from './types';

export const onContainerInit =
	(): ContextMenuAction =>
	({ setState, getState }, { cardId, bulkOpsFlow, ...analyticsContainerProps }) => {
		const { analytics } = getState();

		setState({
			analytics: {
				...analyticsContainerProps,
			},
		});

		if (bulkOpsFlow && shouldFlowBeEnded(analytics, analyticsContainerProps, bulkOpsFlow)) {
			bulkOpsFlow?.end();
		} else if (bulkOpsFlow && hasUserStartedSelecting(analytics, analyticsContainerProps)) {
			bulkOpsFlow?.start();
		}
	};

export const onContainerUpdate =
	(): ContextMenuAction =>
	({ setState, getState }, { cardId, bulkOpsFlow, ...analyticsContainerProps }) => {
		const { analytics } = getState();

		if (hasUserStartedSelecting(analytics, analyticsContainerProps)) {
			if (!bulkOpsFlow) {
				fireErrorAnalytics({
					error: new Error('Trying to start a flow while bulkOpsSession is undefined'),
					meta: {
						id: 'jira-software-context-menu-controller-on-container-update-flow-start-error',
						packageName: 'jiraSoftwareContextMenu',
						teamName: 'deliveroo',
					},
				});
			} else {
				bulkOpsFlow?.start();
			}
		} else if (hasUserUnselectedAll(analytics, analyticsContainerProps)) {
			if (!bulkOpsFlow) {
				fireErrorAnalytics({
					error: new Error('Trying to end a flow while bulkOpsSession is undefined'),
					meta: {
						id: 'jira-software-context-menu-controller-on-container-update-flow-end-error',
						packageName: 'jiraSoftwareContextMenu',
						teamName: 'deliveroo',
					},
				});
			} else {
				bulkOpsFlow?.end();
			}
		}
		setState({
			analytics: {
				...analytics,
				...analyticsContainerProps,
			},
		});
	};

export const fireOpenMenuAnalyticsEvent = ({
	event,
	cardId,
	selectedCardIds,
	openedFrom,
}: FireOpenMenuAnalyticsEventProps) => {
	const attributes = {
		...getCardSelectionAnalytics(selectedCardIds, cardId, true),
		isLinkIssueFromBoardCardEnabled: true,
		jswCmpCardCoverExpCohort: getJswCmpCardCoversExpCohort(event),
		openedFrom,
	};

	// Attributes normally get merged together. We want to override the issue_ids attribute instead
	event.context.push({ attributes: { issue_ids: null } });

	fireUIAnalytics(event, 'cardActions', attributes);
};
