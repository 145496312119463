import { createIndexedDBStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/indexed-db/index.tsx';
import type { AsyncStorage } from '@atlassian/jira-browser-storage-providers/src/types.tsx';
import type { JiraPlanDataRestResponse } from '@atlassian/jira-software-backlog-uif-types';
import { buildCachedEntryKey, isValid } from '../common';

export const uifBacklogCache: AsyncStorage = createIndexedDBStorageProvider('cmp-backlog');

export interface BacklogCachedEntry {
	timestamp: number;
	data: JiraPlanDataRestResponse;
}

export async function setBacklogCachedEntry(
	boardId: string | number,
	entry: BacklogCachedEntry,
): Promise<void> {
	if (!entry.data.localDeviceCacheEnabled) {
		uifBacklogCache.clearAll();
		return Promise.resolve();
	}

	const key = buildCachedEntryKey(boardId);
	return uifBacklogCache.set(key, entry);
}

export async function getBacklogCachedEntry(
	boardId: string | number,
): Promise<BacklogCachedEntry | null> {
	const key = buildCachedEntryKey(boardId);

	try {
		const entry = await uifBacklogCache.get(key);
		if (!entry) {
			return null;
		}
		if (!isValid(entry)) {
			await uifBacklogCache.remove(key);
			return null;
		}
		return entry;
	} catch (err) {
		return null;
	}
}
