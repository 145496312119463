import React from 'react';
import { SpotlightTransition } from '@atlaskit/onboarding';
import { N0 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import {
	ContextualAnalyticsData,
	FireScreenAnalytics,
	SCREEN,
	fireUIAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { JiraSpotlight } from '@atlassian/jira-spotlight/src/ui/jira-spotlight.tsx';
import spotlightImage from './assets/JSM-board-illustration.png';
import { SPOTLIGHT_ID, SPOTLIGHT_TARGET_ID } from './constants';
import messages from './messages';

export const OnboardingSpotlight = ({ onAcknowledge }: { onAcknowledge: () => void }) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	return (
		<ContextualAnalyticsData sourceType={SCREEN} sourceName="boardAdminOnboardingConfigSpotlight">
			<SpotlightTransition>
				<JiraSpotlight
					actions={[
						{
							text: formatMessage(messages.ok),
							onClick: () => {
								fireUIAnalytics(
									createAnalyticsEvent({}),
									'button clicked',
									'boardAdminOnboardingConfigSpotlightOk',
								);
								onAcknowledge();
							},
						},
					]}
					dialogPlacement="bottom right"
					dialogWidth={275}
					targetRadius={3}
					target={SPOTLIGHT_TARGET_ID}
					key={SPOTLIGHT_ID}
					heading={formatMessage(messages.heading)}
					image={spotlightImage}
					targetBgColor={token('elevation.surface.raised', N0)}
					messageId="software-board.header.menu.onboarding-spotlight.jira-spotlight"
					messageType="engagement"
				>
					{formatMessage(messages.description)}
				</JiraSpotlight>
			</SpotlightTransition>
			<FireScreenAnalytics />
		</ContextualAnalyticsData>
	);
};
