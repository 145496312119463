import { ff } from '@atlassian/jira-feature-flagging';
import { useIntl } from '@atlassian/jira-intl';
import { useArchiveIssueModalActions } from '@atlassian/jira-issue-archival-modal/src/controllers/main.tsx';
import { useProjectKey, useIsArchived } from '@atlassian/jira-issue-context-service/src/main.tsx';

import { isSubtaskHierarchyLevel } from '@atlassian/jira-issue-type-hierarchies';
import { useEdition } from '@atlassian/jira-project-context-service/src/main.tsx';
import { PREMIUM_EDITION } from '@atlassian/jira-shared-types/src/edition';
import { getIssueTypeLevelForBoardIssue } from '@atlassian/jira-software-board/src/state/selectors/issue/issue-selectors';
import {
	type MenuNode,
	MenuNodeType,
} from '@atlassian/jira-software-context-menu/src/common/types.tsx';
import { CAN_ARCHIVE_ISSUE } from '../../../../../../../../model/permission/permission-types';
import { useBoardSelector } from '../../../../../../../../state';
import { getPermissionsSelector } from '../../../../../../../../state/selectors/board/board-permissions-selectors';
import { ARCHIVE_ISSUE_MENU_ID } from '../../constants';
import messages from '../../messages';
import type { ContentProps, Props } from './types';

export const useArchiveIssueContent = ({
	isArchiveEnabled,
	onArchiveIssue,
}: ContentProps): MenuNode[] => {
	const { formatMessage } = useIntl();

	if (!isArchiveEnabled) return [];

	return [
		{
			type: MenuNodeType.Item,
			label: formatMessage(messages.archive),
			id: ARCHIVE_ISSUE_MENU_ID,
			onClick: onArchiveIssue,
		},
	];
};

export const useArchiveIssue = ({ issueKey, issueId, isMultiSelectMode }: Props): MenuNode[] => {
	const isArchived = useIsArchived();
	const issueIdArg = issueId.toString();
	const hierarchyLevel = useBoardSelector(getIssueTypeLevelForBoardIssue)(issueId);
	const isSubtask = isSubtaskHierarchyLevel(hierarchyLevel);
	const canArchiveIssue = useBoardSelector(getPermissionsSelector)?.[CAN_ARCHIVE_ISSUE];
	const projectKey = useProjectKey();
	const edition = useEdition(projectKey, true);
	const isPremium = edition === PREMIUM_EDITION;

	const canArchive =
		ff('issue-unarchival_wdpb9') &&
		!isArchived &&
		(canArchiveIssue ?? false) &&
		!isSubtask &&
		isPremium;
	const { openArchiveIssueModal } = useArchiveIssueModalActions();

	return useArchiveIssueContent({
		isArchiveEnabled: canArchive && !isMultiSelectMode,
		onArchiveIssue: () => {
			openArchiveIssueModal({ issueKey, issueId: issueIdArg });
		},
	});
};
