import { useEffect } from 'react';
import type { View } from '../../../common/types/constant.tsx';
import type { ExtraField } from '../../../common/types/menu';
import { isViewSettingsPanelAndTailoredViewExperimentEnabled } from '../../../common/utils';
import {
	getViewSettingsCardDetails as getViewSettingsCardDetailsFromLocalStorage,
	getViewSettingsQuickFilters as getViewSettingsQuickFiltersFromLocalStorage,
	getViewSettingsCompactMode as getViewSettingsCompactModeFromLocalStorage,
	getViewSettingsIsFullscreen as getViewSettingsIsFullscreenFromLocalStorage,
	getViewSettingsCardExtraFields as getViewSettingsCardExtraFieldsFromLocalStorage,
} from '../../../services/local-storage';
import { useViewSettingsActions } from '../../index';

// This hook is used to sync the react-sweet-state store with the values inside localStorage.
export const useSyncFromLocalStorage = (
	boardId: number,
	view: (typeof View)[keyof typeof View],
	cardExtraFields: ExtraField[],
	isDaysInColumnEnabled: boolean,
	isDueDateEnabled: boolean,
	isLabelsEnabled: boolean,
	isEstimateEnabled: boolean,
	isPriorityEnabled: boolean,
) => {
	const {
		setIsFullscreen,
		setViewSettingsCardDetails,
		setViewSettingsQuickFilters,
		setViewSettingsCompactMode,
		setViewSettingsCardExtraFields,
	} = useViewSettingsActions();

	useEffect(() => {
		// card details initial state moved to `useCardDetailsInitialState`
		if (!isViewSettingsPanelAndTailoredViewExperimentEnabled()) {
			const cardDetailsFromLocalStorage = getViewSettingsCardDetailsFromLocalStorage({
				boardId,
				view,
			});
			setViewSettingsCardDetails({
				boardId,
				cardDetailsState: cardDetailsFromLocalStorage,
				view,
			});
		}

		const quickFiltersFromLocalStorage = getViewSettingsQuickFiltersFromLocalStorage({
			boardId,
			view,
		});
		setViewSettingsQuickFilters({
			boardId,
			view,
			showQuickFilters: quickFiltersFromLocalStorage,
		});

		const compactModeFromLocalStorage = getViewSettingsCompactModeFromLocalStorage({
			boardId,
			view,
		});
		setViewSettingsCompactMode({ boardId, view, showCompactMode: compactModeFromLocalStorage });

		const isFullscreenFromLocalStorage = getViewSettingsIsFullscreenFromLocalStorage({
			boardId,
			view,
		});
		setIsFullscreen({ boardId, isFullscreen: isFullscreenFromLocalStorage, view });
	}, [
		boardId,
		view,
		isDaysInColumnEnabled,
		isDueDateEnabled,
		isLabelsEnabled,
		isEstimateEnabled,
		isPriorityEnabled,
		setIsFullscreen,
		setViewSettingsCardDetails,
		setViewSettingsCompactMode,
		setViewSettingsQuickFilters,
		setViewSettingsCardExtraFields,
	]);

	useEffect(() => {
		const cardExtraFieldsFromLocalStorage = getViewSettingsCardExtraFieldsFromLocalStorage({
			boardId,
			view,
			cardExtraFields,
		});
		setViewSettingsCardExtraFields({
			boardId,
			cardExtraFieldsState: cardExtraFieldsFromLocalStorage,
			view,
		});
	}, [boardId, view, cardExtraFields, setViewSettingsCardExtraFields]);
};
