import type { BulkOpsFlow } from '@atlassian/jira-bulk-operations-analytics/src/common/types.tsx';
import type { ContextMenuContainerProps, State } from '../../../common/types';

export const hasUserStartedSelecting = (
	analytics: State['analytics'],
	contextMenuContainerAnalytics: Omit<ContextMenuContainerProps, 'cardId'>,
) =>
	analytics.selectedCardIds.length === 0 &&
	contextMenuContainerAnalytics.selectedCardIds.length > 0;

export const hasUserUnselectedAll = (
	analytics: State['analytics'],
	contextMenuContainerAnalytics: Omit<ContextMenuContainerProps, 'cardId'>,
) =>
	analytics.selectedCardIds.length > 0 &&
	contextMenuContainerAnalytics.selectedCardIds.length === 0;

export const getCardSelectionAnalytics = (
	selectedCardIds: (number | string)[],
	cardId: number | string,
	isMenuOpen: boolean,
) => {
	let cardIds;
	if (isMenuOpen) {
		cardIds = selectedCardIds.includes(cardId) ? selectedCardIds : [cardId];
	} else {
		cardIds = selectedCardIds;
	}
	const numSelectedCards = cardIds.length;

	return {
		numSelectedCards,
		issue_ids: cardIds,
		issue_id: cardId,
	};
};

export const shouldFlowBeEnded = (
	analytics: State['analytics'],
	contextMenuContainerAnalytics: Omit<ContextMenuContainerProps, 'cardId'>,
	bulkOpsFlow?: BulkOpsFlow | null,
) => {
	const sessionID = !!bulkOpsFlow?.getFlowAttributes().bulkOpsSessionID;
	return sessionID && contextMenuContainerAnalytics.selectedCardIds.length === 0;
};
