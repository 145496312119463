import { useCallback, useEffect, useState, useRef } from 'react';
import { NOT_FOUND as HTTP_NOT_FOUND } from '@atlassian/jira-common-constants/src/http-status-codes';
import {
	getUserProperty,
	setUserProperties,
} from '@atlassian/jira-common-rest/src/api/latest/user-properties';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import getMeta from '@atlassian/jira-get-meta';
import {
	fireOperationalAnalytics,
	fireTrackAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';

type State = {
	isSeen: boolean;
	isLoading: boolean;
	error: Error | undefined;
};

type Actions = {
	markAsSeen: () => void;
};

const initialState: State = {
	isSeen: true, // by default we assume the message is seen
	isLoading: false,
	error: undefined,
};

// same deal as with CoordinationClient, we don't want to show changeboarding on synthetic tenants
// src/packages/engagement/src/ui/coordination-client/index.tsx
export const isSyntheticTenant = () => getMeta('ajs-is-synthetic') === 'true';

export const useSeenStatus = <T,>({
	userPropertyKey,
	isSeenValue,
	packageName,
	teamName,
	skipOnboarding,
}: {
	userPropertyKey: string;
	isSeenValue: T;
	packageName: string;
	teamName: string;
	skipOnboarding?: boolean;
}): [State, Actions] => {
	const [state, setState] = useState<State>(initialState);
	const { atlassianAccountId: accountId } = useTenantContext();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const reportErrorDeps = useRef<{ packageName: string; teamName: string }>({
		packageName,
		teamName,
	});
	reportErrorDeps.current = {
		packageName,
		teamName,
	};

	const reportError = useCallback((errorId: string, error: Error) => {
		fireErrorAnalytics({
			meta: {
				id: errorId,
				...reportErrorDeps.current,
			},
			error,
			attributes: {
				isClientFetchError: isClientFetchError(error),
			},
			sendToPrivacyUnsafeSplunk: true,
		});
	}, []);

	// Remove the eslint suppressions when cleaning the FF
	if (ff('jsm-rt-security-m2')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		useEffect(() => {
			if (accountId && !isSyntheticTenant() && !skipOnboarding) {
				setState((oldState) => ({ ...oldState, isLoading: true }));
				getUserProperty<T>(accountId, userPropertyKey)
					.catch((error) => {
						if (error.statusCode === HTTP_NOT_FOUND) {
							// user property has not been set
							return false;
						}
						throw error;
					})
					.then((value) => {
						setState((oldState) => ({
							...oldState,
							isSeen: value === isSeenValue,
							isLoading: false,
						}));
						fireOperationalAnalytics(
							createAnalyticsEvent({}),
							'useSeenStatus.fetchStatus succeeded',
							{
								userPropertyKey,
							},
						);
					})
					.catch((error) => {
						setState((oldState) => ({ ...oldState, isLoading: false, error }));
						reportError('useSeenStatus.fetchStatus', error);
					});
			}
		}, [
			userPropertyKey,
			accountId,
			isSeenValue,
			reportError,
			createAnalyticsEvent,
			skipOnboarding,
		]);

		// eslint-disable-next-line react-hooks/rules-of-hooks
		const markAsSeen = useCallback(() => {
			if (accountId && !isSyntheticTenant() && !skipOnboarding) {
				setState((oldState) => ({
					...oldState,
					isSeen: true, // optimistically update the value
					isLoading: true,
				}));
				setUserProperties(accountId, userPropertyKey, JSON.stringify(isSeenValue))
					.then((res: Response) => {
						if (!res.ok) {
							return res.text().then((errResp: string) => {
								throw new FetchError(res.status, errResp);
							});
						}
						setState((oldState) => ({ ...oldState, isLoading: false }));
						fireTrackAnalytics(createAnalyticsEvent({}), 'useSeenStatus.markAsSeen succeeded', {
							userPropertyKey,
						});
						return Promise.resolve();
					})
					.catch((error) => {
						// leave `isSeen` as true. we don't want to block the user
						setState((oldState) => ({ ...oldState, isLoading: false, error }));
						reportError('useSeenStatus.markAsSeen', error);
					});
			}
		}, [
			accountId,
			userPropertyKey,
			isSeenValue,
			createAnalyticsEvent,
			reportError,
			skipOnboarding,
		]);

		return [state, { markAsSeen }];
	}

	// eslint-disable-next-line react-hooks/rules-of-hooks
	useEffect(() => {
		if (accountId && !isSyntheticTenant()) {
			setState((oldState) => ({ ...oldState, isLoading: true }));
			getUserProperty<T>(accountId, userPropertyKey)
				.catch((error) => {
					if (error.statusCode === HTTP_NOT_FOUND) {
						// user property has not been set
						return false;
					}
					throw error;
				})
				.then((value) => {
					setState((oldState) => ({
						...oldState,
						isSeen: value === isSeenValue,
						isLoading: false,
					}));
					fireOperationalAnalytics(
						createAnalyticsEvent({}),
						'useSeenStatus.fetchStatus succeeded',
						{
							userPropertyKey,
						},
					);
				})
				.catch((error) => {
					setState((oldState) => ({ ...oldState, isLoading: false, error }));
					reportError('useSeenStatus.fetchStatus', error);
				});
		}
	}, [userPropertyKey, accountId, isSeenValue, reportError, createAnalyticsEvent]);

	// eslint-disable-next-line react-hooks/rules-of-hooks
	const markAsSeen = useCallback(() => {
		if (accountId && !isSyntheticTenant()) {
			setState((oldState) => ({
				...oldState,
				isSeen: true, // optimistically update the value
				isLoading: true,
			}));
			setUserProperties(accountId, userPropertyKey, JSON.stringify(isSeenValue))
				.then((res: Response) => {
					if (!res.ok) {
						return res.text().then((errResp: string) => {
							throw new FetchError(res.status, errResp);
						});
					}
					setState((oldState) => ({ ...oldState, isLoading: false }));
					fireTrackAnalytics(createAnalyticsEvent({}), 'useSeenStatus.markAsSeen succeeded', {
						userPropertyKey,
					});
					return Promise.resolve();
				})
				.catch((error) => {
					// leave `isSeen` as true. we don't want to block the user
					setState((oldState) => ({ ...oldState, isLoading: false, error }));
					reportError('useSeenStatus.markAsSeen', error);
				});
		}
	}, [accountId, userPropertyKey, isSeenValue, createAnalyticsEvent, reportError]);

	return [state, { markAsSeen }];
};
