import React, { useState, useCallback, useMemo, useRef, useEffect, type ReactNode } from 'react';
import { styled, keyframes } from '@compiled/react';
import { SpotlightCard, SpotlightPulse } from '@atlaskit/onboarding';
import { Box } from '@atlaskit/primitives';
import { N20, N800 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { layers } from '@atlassian/jira-common-styles/src/main.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useGlobalSearch, isMacOS } from '@atlassian/jira-software-global-search-tracker';
import {
	hasUserSeenSearchFieldChangeboarding,
	setUserSeenSearchFieldChangeboarding,
} from '../../services/have-seen-search-filter-changeboarding';
import messages from './messages';

type Props = {
	children: ReactNode;
	screenName?: string;
	hijackBrowserDefaultSearch?: boolean;
	isCompanyManaged?: boolean;
};

const SearchFilterChangeboarding = ({
	children,
	hijackBrowserDefaultSearch = false,
	screenName,
	isCompanyManaged = false,
}: Props) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [isVisible, setIsVisible] = useState(false);
	const hasShown = useRef(false);

	const onGlobalSearch = useCallback(() => {
		if (!hasShown.current) {
			fireTrackAnalytics(createAnalyticsEvent({}), 'searchFilterChangeboarding shown', {
				isHijackBrowserDefaultSearchEnabled: hijackBrowserDefaultSearch,
			});
			setIsVisible(true);
			hasShown.current = true;
			if (screenName === 'backlog' || screenName === 'board' || screenName === 'roadmap') {
				setUserSeenSearchFieldChangeboarding(screenName);
			}
		}
	}, [createAnalyticsEvent, hijackBrowserDefaultSearch, screenName]);

	const onDismiss = useCallback(() => {
		fireTrackAnalytics(createAnalyticsEvent({}), 'searchFilterChangeboarding dismissed', {
			isHijackBrowserDefaultSearchEnabled: hijackBrowserDefaultSearch,
		});
		setIsVisible(false);
	}, [createAnalyticsEvent, hijackBrowserDefaultSearch]);

	const actions = useMemo(
		() => [{ text: formatMessage(messages.changeboardingConfirmButton), onClick: onDismiss }],
		[formatMessage, onDismiss],
	);
	useEffect(() => {
		const getUserSeenSearchFieldChangeboarding = async () => {
			if (screenName === 'backlog' || screenName === 'board' || screenName === 'roadmap') {
				const seen = await hasUserSeenSearchFieldChangeboarding(
					createAnalyticsEvent({}),
					screenName,
				);

				if (seen) {
					hasShown.current = true;
				}
			}
		};
		getUserSeenSearchFieldChangeboarding();
	}, [createAnalyticsEvent, hijackBrowserDefaultSearch, screenName]);

	const changeBoardingMessage = () => {
		const ctrlKey = isMacOS() ? <Code>Cmnd</Code> : <Code>Ctrl</Code>;
		return hijackBrowserDefaultSearch ? (
			<FormattedMessage
				{...messages.hijackingDefaultSearchMessage}
				values={{
					key: <Code>F</Code>,
					ctrlKey,
				}}
			/>
		) : (
			<FormattedMessage
				{...messages.changeboardingMessage}
				values={{
					key: <Code>f</Code>,
				}}
			/>
		);
	};

	let spotlightCard = (
		<SpotlightCard actions={actions} width={300}>
			<Box>{changeBoardingMessage()}</Box>
		</SpotlightCard>
	);

	if (isCompanyManaged && ff('jsw-uif-ctrl-f5-changeboarding_1ik10')) {
		spotlightCard = (
			<SpotlightCard
				heading={formatMessage(messages.changeboardingUifMessageHeading)}
				actions={actions}
				width={300}
			>
				<Box>{formatMessage(messages.changeboardingUifMessage)}</Box>
			</SpotlightCard>
		);
	}

	useGlobalSearch(onGlobalSearch);

	if (isVisible) {
		return (
			<ChangeboardingContainer>
				<Pulse>{children}</Pulse>
				<SpolightCardContainer>{spotlightCard}</SpolightCardContainer>
			</ChangeboardingContainer>
		);
	}

	return <>{children}</>;
};

export default SearchFilterChangeboarding;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Pulse = styled(SpotlightPulse)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: layers.dialog,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ChangeboardingContainer = styled.div({
	position: 'relative',
});

const spotlightAnimation = keyframes({
	from: {
		opacity: 0,
	},
	to: {
		opacity: 1,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SpolightCardContainer = styled.div({
	position: 'absolute',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: layers.dialog,
	marginTop: token('space.100', '8px'),
	opacity: 0,
	animation: `${spotlightAnimation} 0.2s forwards`,
	whiteSpace: 'initial',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Code = styled.code({
	font: token('font.body.UNSAFE_small'),
	background: token('elevation.surface.overlay', N20),
	color: token('color.text', N800),
	borderRadius: '3px',
	display: 'inline',
	overflowX: 'auto',
	whiteSpace: 'pre-wrap',
	padding: `${token('space.025', '2px')} ${token('space.050', '4px')}`,
});
