import React from 'react';
import { graphql, usePreloadedQuery, type PreloadedQuery } from 'react-relay';
import ProjectBreadcrumb from '@atlassian/jira-breadcrumbs/src/common-breadcrumbs/project/index.tsx';
import { SOFTWARE_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import RedirectIfProjectArchived from '@atlassian/jira-redirect-archived-project/src/ui/index.tsx';
import { useRelayResource } from '@atlassian/jira-relay-utils/src/services/resources/index.tsx';
import type { boardWithRouterRelayQuery } from '@atlassian/jira-relay/src/__generated__/boardWithRouterRelayQuery.graphql';
import type { viewBoardCustomFiltersQuery } from '@atlassian/jira-relay/src/__generated__/viewBoardCustomFiltersQuery.graphql';
import { customFiltersResource } from '@atlassian/jira-router-resources-board-resource';
import { useGetHasSuspended } from '@atlassian/jira-software-suspense-tracker';
import { SubProductUpdater } from '@atlassian/jira-spa-apps-common/src/analytics-sub-product/sub-product-updater/index.tsx';
import { HistoryAdapter } from '@atlassian/jira-spa-router-adapters/src/controllers/history/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { usePathParam } from '@atlassian/react-resource-router';
import { transformAggToSwagData } from '../../../agg-data';
import { renderBoardApp } from '../board-app';
import { NoSoftwareLicense } from '../no-software-license';

type Props = {
	hasSoftwareLicense: boolean;
	boardQueryReference: PreloadedQuery<boardWithRouterRelayQuery>;
};

export const BoardWithRouterRelay = ({ hasSoftwareLicense, boardQueryReference }: Props) => {
	const [paramBoardId] = usePathParam('boardId');
	const tenantContext = useTenantContext();
	const boardId = Number(paramBoardId);

	const aggData = usePreloadedQuery(
		/* eslint-disable @atlassian/relay/unused-fields */
		graphql`
			query boardWithRouterRelayQuery($boardId: ID!) @preloadable {
				boardScope(boardId: $boardId) @required(action: THROW) {
					userSwimlaneStrategy
					board @required(action: THROW) {
						name @required(action: THROW)
						swimlaneStrategy @required(action: THROW)
						hasClearedCards @required(action: THROW)
						rankCustomFieldId @required(action: THROW)
						assignees @required(action: THROW) {
							accountId @required(action: THROW)
							name @required(action: THROW)
							picture @required(action: THROW)
						}
						columns @required(action: THROW) {
							id @required(action: THROW)
							name @required(action: THROW)
							maxCardCount
							columnStatus @required(action: THROW) {
								status @required(action: THROW) {
									id @required(action: THROW)
									name @required(action: THROW)
									category @required(action: THROW)
								}
								cardTypeTransitions @required(action: THROW) {
									transitionId @required(action: THROW)
									name @required(action: THROW)
									status @required(action: THROW) {
										id @required(action: THROW)
									}
									originStatus {
										id @required(action: THROW)
									}
									cardType @required(action: THROW) {
										id @required(action: THROW)
									}
									isGlobal @required(action: THROW)
									isInitial @required(action: THROW)
									hasConditions @required(action: THROW)
								}
							}
							isDone @required(action: THROW)
							isInitial @required(action: THROW)
							cards @required(action: THROW) {
								id @required(action: THROW)
								flagged @required(action: THROW)
								done @required(action: THROW)
								parentId
								activeSprint {
									id @required(action: THROW)
								}
								estimate {
									storyPoints
									originalEstimate {
										value @required(action: THROW)
										valueAsText @required(action: THROW)
									}
								}
								key @required(action: THROW)
								summary @required(action: THROW)
								labels @required(action: THROW)
								assignee {
									accountId @required(action: THROW)
								}
								type @required(action: THROW) {
									id @required(action: THROW)
									name @required(action: THROW)
									iconUrl @required(action: THROW)
								}
								status @required(action: THROW) {
									id @required(action: THROW)
								}
								priority {
									name @required(action: THROW)
									iconUrl @required(action: THROW)
								}
								dueDate
								childCardsMetadata {
									complete @required(action: THROW)
									total @required(action: THROW)
								}
							}
						}
						cardTypes @required(action: THROW) {
							id @required(action: THROW)
							name @required(action: THROW)
							iconUrl @required(action: THROW)
							hierarchyLevelType @required(action: THROW)
						}
						inlineCardCreate @required(action: THROW) {
							enabled @required(action: THROW)
						}
						cardChildren @required(action: THROW) {
							id @required(action: THROW)
							flagged @required(action: THROW)
							done @required(action: THROW)
							parentId
							estimate {
								storyPoints
								originalEstimate {
									value @required(action: THROW)
									valueAsText @required(action: THROW)
								}
							}
							key @required(action: THROW)
							summary @required(action: THROW)
							labels @required(action: THROW)
							assignee {
								accountId @required(action: THROW)
								name @required(action: THROW)
								picture @required(action: THROW)
							}
							type @required(action: THROW) {
								id @required(action: THROW)
								name @required(action: THROW)
								iconUrl @required(action: THROW)
							}
							status @required(action: THROW) {
								id @required(action: THROW)
							}
							priority {
								name @required(action: THROW)
								iconUrl @required(action: THROW)
							}
							dueDate
						}
						cards @required(action: THROW) {
							id @required(action: THROW)
						}
						editConfig @required(action: THROW) {
							inlineColumnEdit @required(action: THROW) {
								enabled @required(action: THROW)
							}
							inlineCardCreate @required(action: THROW) {
								enabled @required(action: THROW)
							}
						}
					}
					sprints(state: [ACTIVE]) @required(action: THROW) {
						id @required(action: THROW)
						name @required(action: THROW)
						goal
						startDate @required(action: THROW)
						endDate @required(action: THROW)
						daysRemaining @required(action: THROW)
						cards @required(action: THROW) {
							id @required(action: THROW)
						}
					}
					features @required(action: THROW) {
						key @required(action: THROW)
						status @required(action: THROW)
						toggle @required(action: THROW)
						category @required(action: THROW)
					}
					projectLocation @required(action: THROW) {
						id @required(action: THROW)
						key @required(action: THROW)
						name @required(action: THROW)
						cardTypes @required(action: THROW) {
							id @required(action: THROW)
							name @required(action: THROW)
							iconUrl @required(action: THROW)
							hierarchyLevelType @required(action: THROW)
						}
					}
					cardParents @required(action: THROW) {
						id @required(action: THROW)
						key @required(action: THROW)
						summary @required(action: THROW)
						status {
							id @required(action: THROW)
							name @required(action: THROW)
							category @required(action: THROW)
						}
						cardType @required(action: THROW) {
							id @required(action: THROW)
							name @required(action: THROW)
							iconUrl @required(action: THROW)
						}
						color
					}
					currentUser @required(action: THROW) {
						permissions @required(action: THROW)
					}
					estimation {
						current {
							customFieldId
						}
					}
				}
			}
		`,
		/* eslint-enable @atlassian/relay/unused-fields */
		boardQueryReference,
	);

	const boardData = {
		data: transformAggToSwagData(aggData),
		loading: false,
		error: null,
	};

	const isCacheMiss = useGetHasSuspended();
	const isCacheHit = !isCacheMiss;

	const { projectLocation } = boardData.data || {};

	const response = useRelayResource<viewBoardCustomFiltersQuery>(customFiltersResource);
	const customFiltersQueryRef = response.queryReference;

	if (!hasSoftwareLicense) {
		return <NoSoftwareLicense />;
	}

	return (
		// @ts-expect-error - TS2739 - Type '{ children: (history: any) => Element; }' is missing the following properties from type 'RouteContext': location, query, route, match, action
		<HistoryAdapter>
			{(history) => (
				<ProjectBreadcrumb
					/* eslint-disable-next-line jira/deprecations/no-base-url */
					baseUrl={tenantContext.baseUrl}
					projectKey={projectLocation.key}
					projectName={projectLocation.name}
					projectId={projectLocation.id}
					isSimplifiedProject
				>
					<>
						<SubProductUpdater subProduct="software" />

						{renderBoardApp({
							rapidViewId: boardId,
							projectKey: projectLocation.key,
							projectType: SOFTWARE_PROJECT,
							context: { tenantContext },
							boardData,
							history,
							isCacheHit,
							customFiltersQueryRef,
						})}
						<RedirectIfProjectArchived projectKey={projectLocation.key} />
					</>
				</ProjectBreadcrumb>
			)}
		</HistoryAdapter>
	);
};
