import React, { useContext } from 'react';
import Button from '@atlaskit/button/new';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, type AnalyticsEvent } from '@atlassian/jira-product-analytics-bridge';
import type { StandupActions } from '../../../common/types';
import { StandupEntryPointContext } from '../../../controllers/context-provider';
import messages from './messages';

export const StartStandupButton = ({ setIsActiveStandup }: StandupActions) => {
	const { formatMessage } = useIntl();
	const { entryPointActions } = useContext(StandupEntryPointContext);
	const triggerRef = useEntryPointButtonTrigger(entryPointActions);

	const handleStartStandup = (analyticsEvent: AnalyticsEvent) => {
		fireUIAnalytics(analyticsEvent, 'startStandup');
		setIsActiveStandup(true);
	};

	return (
		<Button
			ref={triggerRef}
			onClick={(__, analyticsEvent: AnalyticsEvent) => handleStartStandup(analyticsEvent)}
		>
			{formatMessage(messages.startStandupButtonNonfinal)}
		</Button>
	);
};
