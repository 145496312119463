import React from 'react';
import type { StandupControls } from '../../common/types';
import { EndStandupButton } from './end-standup-button';
import { StartStandupButton } from './start-standup-button';

export const StandupControlButtons = ({ isActiveStandup, setIsActiveStandup }: StandupControls) => {
	return isActiveStandup ? (
		<EndStandupButton setIsActiveStandup={setIsActiveStandup} />
	) : (
		<StartStandupButton setIsActiveStandup={setIsActiveStandup} />
	);
};
