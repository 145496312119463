import React from 'react';
import kebabCase from 'lodash/kebabCase';
import { Box, xcss } from '@atlaskit/primitives';
import Select, { type ReactSelectProps, type OptionType } from '@atlaskit/select';
import { layers } from '@atlassian/jira-common-styles/src/main.tsx';
import { ViewAs } from '../../types/constant.tsx';
import { isViewSettingsPanelAndTailoredViewExperimentEnabled } from '../../utils';

export type Props<Option extends OptionType> = {
	title: string;
	testId?: string;
	viewAs?: (typeof ViewAs)[keyof typeof ViewAs];
} & ReactSelectProps<Option>;

export const ViewSettingsSelect = <Option extends OptionType>({
	title,
	testId,
	viewAs = ViewAs.POPUP,
	...restProps
}: Props<Option>) => {
	const id = kebabCase(`jira-software-view-settings-${title}`);

	return (
		<Box
			xcss={
				viewAs === ViewAs.PANEL && isViewSettingsPanelAndTailoredViewExperimentEnabled()
					? panelContainerStyles
					: popupContainerStyles
			}
			testId={testId}
		>
			<Box xcss={labelWrapperStyles}>
				<label htmlFor={id}>{title}</label>
			</Box>
			<Select
				inputId={id}
				menuPortalTarget={document.body}
				styles={{
					menuPortal: (base) => ({ ...base, zIndex: layers.layer }),
				}}
				{...restProps}
			/>
		</Box>
	);
};

const labelWrapperStyles = xcss({
	marginBottom: 'space.050',
});

const popupContainerStyles = xcss({
	marginBlock: 'space.200',
	marginInline: 'space.200',
	minWidth: '200px',
});

const panelContainerStyles = xcss({
	marginBlock: 'space.200',
	marginInline: 'auto',
	minWidth: '200px',
});
