import React, { useCallback, useState, type ReactElement, type ReactNode } from 'react';
import { AVATAR_SIZES } from '@atlaskit/avatar';
import { SpotlightTarget, SpotlightTransition } from '@atlaskit/onboarding';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import CoordinationClient from '@atlassian/jira-engagement/src/ui/coordination-client/index.tsx';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useGlobalRef } from '@atlassian/jira-refs-store';
import { JiraSpotlight } from '@atlassian/jira-spotlight/src/ui/jira-spotlight.tsx';
import { isViewSettingsPanelAndTailoredViewExperimentEnabled } from '../../common/utils';
import {
	ADD_PEOPLE_BUTTON_TARGET,
	VIEW_SETTINGS_BUTTON_TARGET,
	VIEW_SETTINGS_PANEL_MESSAGE_ID,
	VIEW_SETTINGS_PANEL_TARGET,
} from './constants';
import messages from './messages';

export { VIEW_SETTINGS_PANEL_TARGET } from './constants';

type StopFunction = () => void;
type CoordinationStopProviderProps = {
	stop?: StopFunction;
	children: (arg1: StopFunction | undefined) => ReactElement;
};

export const CoordinationStopProvider = ({ stop, children }: CoordinationStopProviderProps) =>
	children(stop);

type Props = {
	onTogglePanel: () => void;
	isViewSettingPanelOpen?: boolean;
};

const ViewSettingsPanelOnboardingBase = ({ onTogglePanel, isViewSettingPanelOpen }: Props) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [viewSettingsPanelRef] = useGlobalRef(VIEW_SETTINGS_PANEL_TARGET);
	const [addPeopleButtonRef] = useGlobalRef(ADD_PEOPLE_BUTTON_TARGET);

	const [activeSpotlight, setActiveSpotlight] = useState<number | null>(0);
	const next = useCallback(
		() => setActiveSpotlight((current) => (current !== null ? current + 1 : 0)),
		[],
	);

	const renderSpotlight = useCallback(
		(stop?: StopFunction) => {
			const finalStepActions = [
				{
					text: formatMessage(messages.doneStepButton),
					onClick: () => {
						isViewSettingPanelOpen && onTogglePanel();

						fireUIAnalytics(
							createAnalyticsEvent({}),
							'button clicked',
							'jswViewSettingsPanelSpotlightDone',
						);

						setActiveSpotlight(null);
						stop && stop();
					},
				},
			];

			const isAddPeopleButtonAvailable = Boolean(addPeopleButtonRef);
			const numberOfSpotlights = isAddPeopleButtonAvailable ? 3 : 2;

			const spotlights = [
				<JiraSpotlight
					key={VIEW_SETTINGS_BUTTON_TARGET}
					target={VIEW_SETTINGS_BUTTON_TARGET}
					heading={formatMessage(messages.viewSettingsButtonStepTitle)}
					actionsBeforeElement={`1/${numberOfSpotlights}`}
					actions={[
						{
							text: formatMessage(messages.nextStepButton),
							onClick: () => {
								!isViewSettingPanelOpen && onTogglePanel();

								fireUIAnalytics(
									createAnalyticsEvent({}),
									'button clicked',
									'jswViewSettingsPanelSpotlightNext',
								);

								next();
							},
						},
						{
							text: formatMessage(messages.dismissStepButton),
							onClick: () => {
								fireUIAnalytics(
									createAnalyticsEvent({}),
									'button clicked',
									'jswViewSettingsPanelSpotlightDismiss',
								);

								setActiveSpotlight(null);
								stop && stop();
							},
						},
					]}
					targetRadius={3}
					targetBgColor={token('elevation.surface', colors.N0)}
					dialogPlacement="bottom right"
					messageId="software-view-settings.ui.view-settings-panel-onboarding.jira-spotlight"
					messageType="engagement"
				>
					{formatMessage(messages.viewSettingsButtonStepContent)}
				</JiraSpotlight>,
				<JiraSpotlight
					key={VIEW_SETTINGS_PANEL_TARGET}
					targetNode={viewSettingsPanelRef}
					actionsBeforeElement={`2/${numberOfSpotlights}`}
					actions={
						isAddPeopleButtonAvailable
							? [
									{
										text: formatMessage(messages.nextStepButton),
										onClick: () => {
											isViewSettingPanelOpen && onTogglePanel();

											fireUIAnalytics(
												createAnalyticsEvent({}),
												'button clicked',
												'jswViewSettingsPanelSpotlightNext',
											);

											next();
										},
									},
									{
										text: formatMessage(messages.dismissStepButton),
										onClick: () => {
											isViewSettingPanelOpen && onTogglePanel();

											fireUIAnalytics(
												createAnalyticsEvent({}),
												'button clicked',
												'jswViewSettingsPanelSpotlightDismiss',
											);

											setActiveSpotlight(null);
											stop && stop();
										},
									},
								]
							: finalStepActions
					}
					targetRadius={10}
					targetBgColor={token('elevation.surface', colors.N0)}
					dialogPlacement="left top"
					messageId="software-view-settings.ui.view-settings-panel-onboarding.jira-spotlight.1"
					messageType="engagement"
				>
					<>
						<p>{formatMessage(messages.viewSettingsPanelFirstPartContent)}</p>
						<p>{formatMessage(messages.viewSettingsPanelSecondPartContent)}</p>
					</>
				</JiraSpotlight>,
				isAddPeopleButtonAvailable ? (
					<JiraSpotlight
						key={ADD_PEOPLE_BUTTON_TARGET}
						targetNode={addPeopleButtonRef}
						heading={formatMessage(messages.addPeopleButtonStepTitle)}
						actionsBeforeElement={`3/${numberOfSpotlights}`}
						actions={finalStepActions}
						targetRadius={AVATAR_SIZES.medium}
						targetBgColor={token('elevation.surface', colors.N0)}
						dialogPlacement="bottom left"
						messageId="software-view-settings.ui.view-settings-panel-onboarding.jira-spotlight.2"
						messageType="engagement"
					>
						{formatMessage(messages.addPeopleButtonStepContent)}
					</JiraSpotlight>
				) : null,
			];

			return activeSpotlight != null ? spotlights[activeSpotlight] : null;
		},
		[
			activeSpotlight,
			createAnalyticsEvent,
			formatMessage,
			isViewSettingPanelOpen,
			next,
			onTogglePanel,
			viewSettingsPanelRef,
			addPeopleButtonRef,
		],
	);

	return (
		<CoordinationClient messageId={VIEW_SETTINGS_PANEL_MESSAGE_ID} messageType="engagement">
			<CoordinationStopProvider>
				{(stop) => <SpotlightTransition>{renderSpotlight(stop)}</SpotlightTransition>}
			</CoordinationStopProvider>
		</CoordinationClient>
	);
};

export const ViewSettingsPanelOnboarding = componentWithCondition(
	isViewSettingsPanelAndTailoredViewExperimentEnabled,
	ViewSettingsPanelOnboardingBase,
	() => null,
);

export const ViewSettingsPanelButtonSpotlight = ({ children }: { children: ReactNode }) => {
	if (isViewSettingsPanelAndTailoredViewExperimentEnabled()) {
		return <SpotlightTarget name={VIEW_SETTINGS_BUTTON_TARGET}>{children}</SpotlightTarget>;
	}

	return children;
};
