import {
	createActionsHook,
	createHook,
	createStateHook,
	createStore,
	createContainer,
} from '@atlassian/react-sweet-state';
import { initialState } from '../common/constants';
import type { View } from '../common/types/constant.tsx';
import type { State } from '../common/types/state.tsx';
import { actions, type Actions } from './actions';
import {
	getIsFullscreen,
	getShowVersionPanel,
	getShowEpicPanel,
	getShowQuickFilters,
	getShowCompactMode,
	getShowPanels,
	getShowEpics,
	getShowVersions,
	getShowStatuses,
	getShowUnscheduledColumn,
	getShowIssueLinksStats,
	getShowIssueType,
	getShowIssueKey,
	getShowPriority,
	getShowDevelopment,
	getShowAssignee,
	getShowEstimate,
	getShowCardExtraField,
	getShowDaysInColumn,
	getHasCardExtraFields,
	getCardFieldsSettings,
	getShowCardCovers,
	getShowOfftrackDependencyLines,
	getIssueIdsToShowDependencies,
} from './selectors';

const Store = createStore<State, Actions>({
	name: 'ViewSettings',
	initialState,
	actions,
});

export const ViewSettingsContainer = createContainer<
	State,
	Actions,
	{ view: (typeof View)[keyof typeof View] }
>(Store, {
	onInit: actions.initState,
});

export const useViewSettings = createHook(Store);

export const useViewSettingsActions = createActionsHook(Store);

export const useIsFullscreen = createStateHook(Store, {
	selector: getIsFullscreen,
});

export const useShowVersionPanel = createStateHook(Store, {
	selector: getShowVersionPanel,
});

export const useShowEpicPanel = createStateHook(Store, {
	selector: getShowEpicPanel,
});

export const useShowQuickFilters = createStateHook(Store, {
	selector: getShowQuickFilters,
});

export const useShowCompactMode = createStateHook(Store, {
	selector: getShowCompactMode,
});

export const useShowPanels = createStateHook(Store, {
	selector: getShowPanels,
});

export const useShowEpics = createStateHook(Store, {
	selector: getShowEpics,
});

export const useShowVersions = createStateHook(Store, {
	selector: getShowVersions,
});

export const useShowStatuses = createStateHook(Store, {
	selector: getShowStatuses,
});

export const useShowUnscheduledColumn = createStateHook(Store, {
	selector: getShowUnscheduledColumn,
});

export const useShowIssueLinksStats = createStateHook(Store, {
	selector: getShowIssueLinksStats,
});

export const useShowIssueType = createStateHook(Store, {
	selector: getShowIssueType,
});

export const useShowIssueKey = createStateHook(Store, {
	selector: getShowIssueKey,
});

export const useShowPriority = createStateHook(Store, {
	selector: getShowPriority,
});

export const useShowDevelopment = createStateHook(Store, {
	selector: getShowDevelopment,
});

export const useShowAssignee = createStateHook(Store, {
	selector: getShowAssignee,
});

export const useShowEstimate = createStateHook(Store, {
	selector: getShowEstimate,
});

export const useShowCardExtraFields = createStateHook(Store, {
	selector: getShowCardExtraField,
});

export const useShowDaysInColumn = createStateHook(Store, {
	selector: getShowDaysInColumn,
});

export const useShowCardCovers = createStateHook(Store, {
	selector: getShowCardCovers,
});

export const useHasCardExtraFields = createStateHook(Store, {
	selector: getHasCardExtraFields,
});

export const useCardFieldsSettings = createStateHook(Store, {
	selector: getCardFieldsSettings,
});

export const useShowOfftrackDependencyLines = createStateHook(Store, {
	selector: getShowOfftrackDependencyLines,
});

export const useIssueIdsToShowDependencies = createStateHook(Store, {
	selector: getIssueIdsToShowDependencies,
});
