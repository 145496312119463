import { sharedStyles } from '@atlassian/jira-platform-board-kit';

export const USER_FETCH_URL = '/rest/api/2/user/assignable/search';
export const SUMMARY_CONFIG_TITLE = 'Summary';
export const STORY_POINT_CONFIG_TITLE = 'Story point estimate';
export const ORIGINAL_ESTIMATE_CONFIG_TITLE = 'Original estimate';
export const ASSIGNEE_CONFIG_TITLE = 'Assignee';
export const PARENT_CONFIG_TITLE = 'Parent';
export const INLINE_EDITING_FIELD_ZINDEX = sharedStyles.zIndex.stickySwimlaneBar + 1;

export const CARD_COVER_SPOTLIGHT_TARGET_ID =
	'jsw-cmp-card-cover-meatball-menu-changeboarding-spotlight-target';
