import { chain } from 'icepick';
import queryString from 'query-string';
import { fg } from '@atlassian/jira-feature-gating';
import type { Location } from '@atlassian/jira-router';
import type { Panels } from '../types/constant.tsx';
import type { IncrementPlanningSettingsState, PanelState } from '../types/state.tsx';
import {
	type IncrementPlanningSettingsQuery,
	QUERY_PARAM_EPICS,
	QUERY_PARAM_VERSIONS,
	QUERY_PARAM_UNSCHEDULED_WORK,
	QUERY_PARAM_SHOW_OFFTRACK_DEPENDENCY_LINES,
	QUERY_PARAM_ISSUE_IDS_TO_SHOW_DEPENDENCIES,
} from './types';

export { useSendAnalyticsEvent } from './hooks/use-send-analytics-event/index.tsx';

export const parseSearchQuery = (
	search: string,
): Record<(typeof Panels)[keyof typeof Panels], boolean> => {
	const searchParams = queryString.parse(search);

	return {
		EPIC_PANEL: searchParams[QUERY_PARAM_EPICS] === 'visible',
		VERSION_PANEL: searchParams[QUERY_PARAM_VERSIONS] === 'visible',
	};
};

export const setPanels = (panels: PanelState, location: Location) => {
	const search = chain({
		...queryString.parse(location.search),
	});

	search.unset(QUERY_PARAM_EPICS);
	search.unset(QUERY_PARAM_VERSIONS);

	if (panels.EPIC_PANEL) {
		search.set(QUERY_PARAM_EPICS, 'visible');
	}

	if (panels.VERSION_PANEL) {
		search.set(QUERY_PARAM_VERSIONS, 'visible');
	}

	return {
		...location,
		search: queryString.stringify(search.value()),
	};
};

export const parseSearchQueryForIPBoard = (search: string): IncrementPlanningSettingsQuery => {
	const searchParams = queryString.parse(search);

	if (!fg('dependency_visualisation_program_board_fe_and_be')) {
		return {
			SHOW_UNSCHEDULED_COLUMN:
				searchParams[QUERY_PARAM_UNSCHEDULED_WORK] === 'hidden' ? false : undefined,
			SHOW_OFFTRACK_DEPENDENCY_LINES: false,
			ISSUE_IDS_TO_SHOW_DEPENDENCIES: [],
		};
	}
	const issuesQuery = searchParams[QUERY_PARAM_ISSUE_IDS_TO_SHOW_DEPENDENCIES]
		? decodeURIComponent(searchParams[QUERY_PARAM_ISSUE_IDS_TO_SHOW_DEPENDENCIES])
		: undefined;
	const issuesSplit = issuesQuery ? issuesQuery.split(',') : [];
	const issueIdsToShowDependencies: string[] = [];
	issuesSplit.forEach((issueId) => {
		const trimmedIssueId = issueId.trim();
		if (trimmedIssueId.length) {
			issueIdsToShowDependencies.push(trimmedIssueId);
		}
	});

	return {
		SHOW_UNSCHEDULED_COLUMN:
			searchParams[QUERY_PARAM_UNSCHEDULED_WORK] === 'hidden' ? false : undefined,
		SHOW_OFFTRACK_DEPENDENCY_LINES:
			searchParams[QUERY_PARAM_SHOW_OFFTRACK_DEPENDENCY_LINES] === 'visible' ? true : undefined,
		ISSUE_IDS_TO_SHOW_DEPENDENCIES: issueIdsToShowDependencies,
	};
};

export const setIncrementPlanningSettingsQuery = (
	incrementPlanningSettings: IncrementPlanningSettingsState,
	location: Location,
) => {
	const search = chain({
		...queryString.parse(location.search),
	});

	search.unset(QUERY_PARAM_UNSCHEDULED_WORK);

	if (!incrementPlanningSettings.SHOW_UNSCHEDULED_COLUMN) {
		search.set(QUERY_PARAM_UNSCHEDULED_WORK, 'hidden');
	}
	if (fg('dependency_visualisation_program_board_fe_and_be')) {
		search.unset(QUERY_PARAM_SHOW_OFFTRACK_DEPENDENCY_LINES);
		search.unset(QUERY_PARAM_ISSUE_IDS_TO_SHOW_DEPENDENCIES);

		if (incrementPlanningSettings.SHOW_OFFTRACK_DEPENDENCY_LINES) {
			search.set(QUERY_PARAM_SHOW_OFFTRACK_DEPENDENCY_LINES, 'visible');
		}

		if (
			incrementPlanningSettings.ISSUE_IDS_TO_SHOW_DEPENDENCIES &&
			incrementPlanningSettings.ISSUE_IDS_TO_SHOW_DEPENDENCIES.length
		) {
			search.set(
				QUERY_PARAM_ISSUE_IDS_TO_SHOW_DEPENDENCIES,
				encodeURIComponent(incrementPlanningSettings.ISSUE_IDS_TO_SHOW_DEPENDENCIES.toString()),
			);
		}
	}

	return {
		...location,
		search: queryString.stringify(search.value()),
	};
};

// eslint-disable-next-line jira/ff/inline-usage
export const isViewSettingsPanelAndTailoredViewExperimentEnabled = () =>
	fg('jira_view_settings_additional_fields');
