/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { token } from '@atlaskit/tokens';
import { visuallyHiddenStyles } from '@atlassian/jira-accessibility/src/common/ui/screenreader-text/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import type { CMPCustomFilter, Filters } from '../../common/types';
import FilterOption from './filter-option';
import messages from './messages';

type Props = {
	onChange: (filters: Filters, analyticsEvent: UIAnalyticsEvent) => void;
	selectedFilters: Set<string>;
	filters: CMPCustomFilter[];
};

const FilterSelectionBar = ({ onChange, selectedFilters, filters }: Props) => {
	const { formatMessage } = useIntl();

	return (
		<fieldset
			data-testid="software-filters.ui.filter-selection-bar.filter-selection-bar"
			css={styles}
		>
			{/* eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766 */}
			<legend css={css(visuallyHiddenStyles)}>
				{formatMessage(messages.filterToolBarAriaLabel)}
			</legend>
			{filters.map((filter) => (
				<FilterOption
					key={`filter-option-${filter.id}`}
					onChange={onChange}
					selectedFilters={selectedFilters}
					filter={filter}
				/>
			))}
		</fieldset>
	);
};

export default FilterSelectionBar;
const styles = css({
	width: '100%',
	overflow: 'wrap',
	display: 'flex',
	gap: token('space.100', '8px'),
	marginTop: token('space.100', '8px'),
	flexWrap: 'wrap',
	listStyle: 'none',
});
