import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import { UNASSIGNED_EPIC_ID } from '../constants';
import type { FilterableIssue, MatcherCreator } from '../types';

export const issueParentMatcherCreator: MatcherCreator<IssueId[], FilterableIssue> = (
	issueParentFilter,
	{ issues },
) => {
	const issueParentSet = new Set(issueParentFilter);

	const isAncestorInFilter = (id?: IssueId, checked: IssueId[] = []): boolean => {
		if (id === undefined) return issueParentSet.has(UNASSIGNED_EPIC_ID);
		if (issueParentSet.has(id)) return true;
		if (checked.includes(id)) return false;

		if (issues === undefined) return false;

		const issue = issues.hash[id];

		if (issue === undefined) return false;

		const { parentId } = issue;
		return isAncestorInFilter(parentId, checked.concat(id));
	};

	return ({ id, parentId }) => {
		if (id === undefined) return false;
		if (issueParentSet.has(id)) return true;
		if (id === parentId) return false;

		return isAncestorInFilter(parentId, [id]);
	};
};
