import React, {
	type ComponentPropsWithoutRef,
	type ElementType,
	Component,
	type ComponentType,
} from 'react';
import { styled } from '@compiled/react';
import type { PreloadedQuery } from 'react-relay';
import { type CreateUIAnalyticsEvent, withAnalyticsEvents } from '@atlaskit/analytics-next';
import { token } from '@atlaskit/tokens';
import { boardMenuItems } from '@atlassian/jira-automation-menu/src/common/constants.tsx';
import type AutomationMenuDI from '@atlassian/jira-automation-menu/src/ui/main.tsx';
import type BreadcrumbRendererDI from '@atlassian/jira-breadcrumbs/src/breadcrumb-renderer/index.tsx';
import PageHeader from '@atlassian/jira-common-components-page-header';
import type { ProjectType } from '@atlassian/jira-common-constants';
import type { Locale } from '@atlassian/jira-common-constants/src/supported-locales';
import { BOARDS_ITEM_TYPE } from '@atlassian/jira-favourite-change-provider/src/model/constants.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import ProjectCreateImportProgress from '@atlassian/jira-project-create-import-progress';
import type { viewBoardCustomFiltersQuery } from '@atlassian/jira-relay/src/__generated__/viewBoardCustomFiltersQuery.graphql';
import { toProjectKey, toProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import { BoardConnectToolbar } from '@atlassian/jira-software-connect-toolbar/src/ui/board-connect-toolbar/index.tsx';
import { StandupControlButtons } from '@atlassian/jira-standups/src/ui/control-buttons/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment';
import type { IssueKey, IssueId } from '../../model/issue/issue-types';
import type {
	RapidViewId,
	ProjectId,
	ProjectKey,
	ProjectName,
} from '../../model/software/software-types';
import type CompleteSprintButtonDI from './complete-sprint-button';
import type ControlsBarDI from './controls-bar';
import type BoardOperationsDI from './extension';
import type FavouriteButtonDI from './favourite-button';
import FeedbackButton from './feedback-collector-button';
import { FullscreenButton } from './fullscreen-button';
import type MenuDI from './menu';
import type ReleaseVersionDI from './release-version';
import { Separator } from './separator';
import { JswShareButton } from './share-button';
import { SprintGoal } from './sprint-goal';
import { SprintGoalPanel } from './sprint-goal-panel';
import type SprintRemainingDaysDI from './sprint-remaining-days';
import type TitleDI from './title';

type DIType<CT extends ElementType> = ComponentType<ComponentPropsWithoutRef<CT>>;

export type StateProps = {
	isCompleteSprintButtonVisible: boolean;
	isMenuVisible: boolean;
	/**
	 * True if:
	 * - the "Hide menus" CMP display option is flipped to true; or
	 * - for Increment Planning board from Advanced Roadmaps
	 */
	isHeaderCompactMode: boolean;
	isCMPBoard: boolean;
	isJSWBoard: boolean;
	isIncrementPlanningBoard: boolean;
	isSprintRemainingDaysVisible: boolean;
	isSprintsEnabled: boolean;
	hasActiveSprint: boolean;
	canManageAutomations: boolean;
	locale: Locale;
	baseUrl: string;
	rapidViewId: RapidViewId;
	Title: DIType<typeof TitleDI>;
	sprintGoal: string | null;
	ControlsBar: DIType<typeof ControlsBarDI>;
	Menu: DIType<typeof MenuDI>;
	ReleaseVersion: DIType<typeof ReleaseVersionDI>;
	CompleteSprintButton: DIType<typeof CompleteSprintButtonDI>;
	FavoriteButton: DIType<typeof FavouriteButtonDI>;
	SprintRemainingDays: DIType<typeof SprintRemainingDaysDI>;
	BoardOperations: DIType<typeof BoardOperationsDI>;
	BreadcrumbRenderer: DIType<typeof BreadcrumbRendererDI>;
	AutomationMenu: DIType<typeof AutomationMenuDI>;
	projectKey: ProjectKey;
	projectId: ProjectId;
	projectType: ProjectType;
	projectName: ProjectName;
	selectedIssueKeys: IssueKey[];
	selectedIssueIds?: IssueId[];
	isReleaseVersionVisible: boolean;
	boardName: string;
	shouldRenderAutomationButton: boolean;
	shouldRenderFavouriteButton: boolean;
	shouldRenderReleaseButton: boolean;
	shouldRenderFeedbackCollectorButton: boolean;
	shouldRenderViewSettingsCardDetailsOption: boolean;
	boardType: string;
	/** This can be removed when removing Capability.IS_TAB_NAVIGATION */
	isTabNavigation: boolean;
};

export type OwnProps = {
	customFiltersQueryRef: PreloadedQuery<viewBoardCustomFiltersQuery> | null | undefined;
	isEmbedView: boolean;
	isActiveStandup: boolean;
	setIsActiveStandup: React.Dispatch<React.SetStateAction<boolean>>;
};

export type Props = StateProps & OwnProps;

// eslint-disable-next-line jira/react/no-class-components
export class Header extends Component<Props & { createAnalyticsEvent: CreateUIAnalyticsEvent }> {
	static defaultProps = {
		sprintGoal: null,
		selectedIssueKeys: [],
	};

	renderSprintGoalNavV3() {
		return this.props.sprintGoal && <SprintGoal goal={this.props.sprintGoal} />;
	}

	renderBaseActionsNavV3() {
		const {
			baseUrl,
			isCMPBoard,
			isIncrementPlanningBoard,
			ReleaseVersion,
			isCompleteSprintButtonVisible,
			CompleteSprintButton,
			FavoriteButton,
			isSprintRemainingDaysVisible,
			SprintRemainingDays,
			BoardOperations,
			AutomationMenu,
			rapidViewId,
			canManageAutomations,
			projectKey,
			projectId,
			projectType,
			selectedIssueKeys,
			selectedIssueIds,
			isReleaseVersionVisible,
			isHeaderCompactMode,
			isEmbedView,
			boardName,
			shouldRenderAutomationButton,
			shouldRenderFavouriteButton,
			shouldRenderReleaseButton,
			shouldRenderFeedbackCollectorButton,
			boardType,
			createAnalyticsEvent,
			isJSWBoard,
			isActiveStandup,
			setIsActiveStandup,
		} = this.props;

		const automationMenu = shouldRenderAutomationButton && (
			<AutomationMenu
				items={boardMenuItems}
				projectType={projectType}
				projectId={toProjectId(`${projectId}`)}
				projectKey={toProjectKey(projectKey)} // software type to shared type
				isSimplified // Ignored in project aware BoardMenu
				isUIF={isCMPBoard} // Can be set to true always eventually
				canManageAutomations={canManageAutomations}
				selectedIssueKeys={selectedIssueKeys}
				// Cast issueId as number[] for now, until we need AutomationMenu for ARJ Increment planning board
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				selectedIssueIds={selectedIssueIds as number[]}
				key="automationButton"
			/>
		);
		const favButton = shouldRenderFavouriteButton && (
			<FavoriteButton
				key="favouriteButton"
				baseUrl={baseUrl}
				itemId={rapidViewId}
				itemType={BOARDS_ITEM_TYPE}
				favoriteItemName={boardName}
				tooltipPosition="top"
			/>
		);

		const fullscreenButton = !isEmbedView ? (
			<FullscreenButton boardId={Number(rapidViewId)} key="fullscreenButton" />
		) : null;

		const showShareButton = !isIncrementPlanningBoard;

		const shareContentType = `${boardType}_board`;

		const onShareButtonClick = () => {
			const analyticsEvent = createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'button',
			});
			fireUIAnalytics(analyticsEvent, 'shareContentType', {
				contentType: shareContentType,
			});
		};

		const shareButton = showShareButton && (
			<JswShareButton
				projectId={toProjectId(`${projectId}`)}
				key="shareButton"
				shareContentType={shareContentType}
				onTriggerButtonClick={onShareButtonClick}
			/>
		);

		const sprintRemainingDays = !isHeaderCompactMode && isSprintRemainingDaysVisible && (
			<SprintRemainingDays key="SprintRemainingButton" />
		);

		const standupControlButton = !isIncrementPlanningBoard &&
			isJSWBoard &&
			expVal('jira-in-product-standups', 'is_enabled', false) && (
				<StandupControlButtons
					isActiveStandup={isActiveStandup}
					setIsActiveStandup={setIsActiveStandup}
				/>
			);

		return (
			<ActionsWrapper>
				{shouldRenderFeedbackCollectorButton && <FeedbackButton />}

				{sprintRemainingDays}
				{isSprintRemainingDaysVisible && <Separator key="sprint-info-separator" />}

				<IconButtonWrapper>
					{[automationMenu, favButton, shareButton, fullscreenButton, standupControlButton]}
				</IconButtonWrapper>
				{!isEmbedView && !isHeaderCompactMode && isCompleteSprintButtonVisible ? (
					<CompleteSprintButton />
				) : null}
				{!isHeaderCompactMode && <BoardOperations />}
				{shouldRenderReleaseButton && !isHeaderCompactMode && isReleaseVersionVisible && (
					<ReleaseVersion rapidViewId={rapidViewId} />
				)}

				{!isHeaderCompactMode && isCMPBoard && (
					<BoardConnectToolbar boardId={rapidViewId} projectKey={projectKey} mode="work" />
				)}
			</ActionsWrapper>
		);
	}

	renderBaseActionsNavV4() {
		const { shouldRenderFeedbackCollectorButton } = this.props;

		return shouldRenderFeedbackCollectorButton && <FeedbackButton />;
	}

	renderMenu() {
		const { isEmbedView, isMenuVisible, Menu } = this.props;
		if (!isMenuVisible || isEmbedView) {
			return null;
		}

		return <Menu />;
	}

	renderBaseActionsAndMenu() {
		return (
			<ActionsWrapper>
				{this.renderBaseActionsNavV3()}
				{this.renderMenu()}
			</ActionsWrapper>
		);
	}

	render() {
		const {
			ControlsBar,
			Title,
			BreadcrumbRenderer,
			isSprintsEnabled,
			BoardOperations,
			hasActiveSprint,
			customFiltersQueryRef,
			isEmbedView,
			rapidViewId,
			isHeaderCompactMode,
			isIncrementPlanningBoard,
			shouldRenderViewSettingsCardDetailsOption,
			projectKey,
			projectName,
			isTabNavigation,
			isCMPBoard,
			isActiveStandup,
			setIsActiveStandup,
		} = this.props;

		/**
		 * In Advanced Roadmaps, we're embedding the JSW board into an "Increment" page
		 * which already has a <PageHeader /> with its own breadcrumbs and call-to-action buttons
		 * therefore all we need is the <ControlsBar />, nothing else.
		 */
		if (isIncrementPlanningBoard) {
			return (
				<UFOSegment name="software-board-header">
					<IncrementPlanningHeaderWrapper>
						<ControlsBar
							customFiltersQueryRef={null}
							hasActiveSprint={false}
							isSprintsEnabled={false}
							leftHandSideActions={
								isTabNavigation && fg('remove_plans_fullscreen_button_in_tabs_nav')
									? null
									: this.renderBaseActionsNavV3()
							}
							rapidViewId={Number(rapidViewId)}
							showCardDetails={shouldRenderViewSettingsCardDetailsOption}
							isActiveStandup={isActiveStandup}
							setIsActiveStandup={setIsActiveStandup}
						>
							{this.renderMenu()}
						</ControlsBar>
					</IncrementPlanningHeaderWrapper>
				</UFOSegment>
			);
		}

		const breadcrumbs = (
			<BreadcrumbContainer data-fullscreen-id="fullscreen-board-breadcrumbs">
				<BreadcrumbRenderer />
				{projectKey && (
					<ProjectCreateImportProgress projectKey={projectKey} projectName={projectName} />
				)}
			</BreadcrumbContainer>
		);

		return (
			<UFOSegment name="software-board-header">
				{isTabNavigation ? (
					/* FY25 Nav v4 aka. Tab Nav caused the "reshuffle" of the header:
					 * - automationMenu, favButton, shareButton => now above the tabs
					 * - fullscreenButton => moved to <ControlBar>
					 * - sprint details & controls => moved to a flyout under a new button
					 * only needed things left = Connect extension points
					 * ALSO we remove the "isHeaderCompactMode" logic = moved outside of this func
					 */
					<HeaderWrapperNavV4>
						{!isEmbedView && <SprintGoalPanel />}
						<ControlsBar
							isSprintsEnabled={isSprintsEnabled}
							hasActiveSprint={hasActiveSprint}
							customFiltersQueryRef={customFiltersQueryRef}
							// @ts-expect-error TS2322 - RapidViewId is string but controls expect number
							rapidViewId={rapidViewId}
							leftHandSideActions={this.renderBaseActionsNavV4()}
							showCardDetails={shouldRenderViewSettingsCardDetailsOption}
							isActiveStandup={isActiveStandup}
							setIsActiveStandup={setIsActiveStandup}
						>
							<BoardOperations />
							{isCMPBoard && (
								<BoardConnectToolbar boardId={rapidViewId} projectKey={projectKey} mode="work" />
							)}
							{this.renderMenu()}
						</ControlsBar>
					</HeaderWrapperNavV4>
				) : (
					<HeaderWrapperNavV3>
						<PageHeader
							truncateTitle
							disableTitleStyles
							bottomBar={
								<ControlsBar
									isSprintsEnabled={isSprintsEnabled}
									hasActiveSprint={hasActiveSprint}
									customFiltersQueryRef={customFiltersQueryRef}
									// @ts-expect-error TS2322 - RapidViewId is string but controls expect number
									rapidViewId={rapidViewId}
									leftHandSideActions={isHeaderCompactMode && this.renderBaseActionsNavV3()}
									showCardDetails={shouldRenderViewSettingsCardDetailsOption}
									isActiveStandup={isActiveStandup}
									setIsActiveStandup={setIsActiveStandup}
								>
									{isHeaderCompactMode && this.renderMenu()}
								</ControlsBar>
							}
							actions={!isHeaderCompactMode && this.renderBaseActionsAndMenu()}
							breadcrumbs={breadcrumbs}
						>
							{!isHeaderCompactMode && (
								<>
									<Title />
									{this.renderSprintGoalNavV3()}
								</>
							)}
						</PageHeader>
					</HeaderWrapperNavV3>
				)}
			</UFOSegment>
		);
	}
}

export default withAnalyticsEvents()(Header);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const BreadcrumbContainer = styled.div({
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'&.fullscreen': {
		display: 'none',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ActionsWrapper = styled.div({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	gap: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeaderWrapperNavV3 = styled.div({
	padding: `0 ${token('space.500', '40px')}`,
});
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeaderWrapperNavV4 = styled.div({
	padding: `${token('space.300', '24px')} ${token('space.500', '40px')} 0`,
});
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IncrementPlanningHeaderWrapper = styled.div({
	padding: `0 ${token('space.300', '24px')} 0 ${token('space.500', '40px')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconButtonWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
});
