import React, { useCallback, useRef, useContext, useEffect, useMemo, type ReactNode } from 'react';
import { styled } from '@compiled/react';
import isNil from 'lodash/fp/isNil';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import ButtonGroup from '@atlaskit/button/button-group';
import { DateTimePicker } from '@atlaskit/datetime-picker';
import Form, { ErrorMessage, Field, FormSection } from '@atlaskit/form';
import Heading from '@atlaskit/heading';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import Link from '@atlaskit/link';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
	ModalTransition,
} from '@atlaskit/modal-dialog';
import { Box, Stack, Inline, xcss, Text } from '@atlaskit/primitives';
import SectionMessage from '@atlaskit/section-message';
import Select, { type ValueType } from '@atlaskit/select';
import TextField from '@atlaskit/textfield';
import { N500 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { getDefaultTimes } from '@atlassian/eoc-day-time-interval';
import { layers } from '@atlassian/jira-common-styles/src';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import planIncrementCommonMessages from '@atlassian/jira-portfolio-3-plan-increment-common/src/messages';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useLocale } from '@atlassian/jira-tenant-context-controller';
import {
	DEFAULT_ITERATION_LENGTH,
	DEFAULT_ITERATIONS_COUNT,
	ITERATIONS_COUNT_OPTIONS,
} from '../../common/constants';
import type { Option, NumberOption } from '../../common/types';
import Ipcontext from '../../services/context';
import { useIncrementCreationService } from '../../services/create-increment';
import { useFetchCustomFields } from '../../services/fetch-custom-fields';
import {
	BoardScopeFilterControl,
	BoardScopeFilterOption,
	BoardScopeFilterSingleValue,
	ScopeFilterValue,
} from './board-scope-filter';
import CalculatedBoardEndDate from './calculated-end-date';
import IssueCount from './issue-count';
import IterationsCount from './iterations-count';
import messages from './messages';
import type { FormData, Props } from './types';
import { getNextQuarterStartDate, getWeekStartDay } from './utils';

const DOC_LINK =
	'https://support.atlassian.com/jira-software-cloud/docs/create-a-new-program-board-in-your-plan/';

const IncrementCreation = ({ onIncrementCreated, isOpen = false, onClose }: Props) => {
	const { planId } = useContext(Ipcontext);
	const {
		customFields,
		fetchCustomFields,
		isLoading: isLoadingCustomFields,
	} = useFetchCustomFields({ planId });

	const customFieldOptions = useMemo(() => {
		if (isLoadingCustomFields) {
			return [];
		}
		return (customFields || []).map((f) => ({
			label: f.title,
			value: f.id,
			project: f.project,
		}));
	}, [customFields, isLoadingCustomFields]);

	const { saving, error, save } = useIncrementCreationService();
	const { formatMessage } = useIntl();
	const locale = useLocale();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const handleCancelButtonClick = useCallback(() => {
		onClose();
	}, [onClose]);

	const focusRef = useRef<HTMLElement>();

	const getStartDate = useCallback((): string => getNextQuarterStartDate(new Date()), []);

	const handleSubmitForm = useCallback(
		async (data: FormData) => {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const errorMessages: any = {};
			if ((data.name?.length || 0) < 1) {
				errorMessages.name = formatMessage(messages.nameEmptyError);
			}
			if ((data.start?.length || 0) < 1) {
				errorMessages.start = formatMessage(messages.startDateEmptyError);
			}
			if (!isNil(data.filterFieldId) && isNil(data.filterFieldValue)) {
				errorMessages.filterFieldValue = formatMessage(messages.fieldValueEmptyError);
			}
			if (isNil(data.name) || isNil(data.start) || Object.keys(errorMessages).length > 0) {
				return errorMessages;
			}

			const newBoard = {
				name: data.name,
				start: new Date(data.start).getTime(),
				iterationsCount: fg('program_board_flexible_cadence')
					? data.iterationsCount?.value
					: DEFAULT_ITERATIONS_COUNT,
				iterationLength: fg('program_board_flexible_cadence')
					? data.iterationLength?.value
					: DEFAULT_ITERATION_LENGTH,
				filterFieldId: data.filterFieldId?.value,
				filterFieldValue: data.filterFieldValue?.value,
			};
			const result = await save(newBoard);
			if (result !== undefined) {
				onIncrementCreated(result);
				fireUIAnalytics(createAnalyticsEvent({ action: 'added', actionSubject: 'programBoard' }));
				onClose();
			}
		},
		[save, onIncrementCreated, onClose, formatMessage, createAnalyticsEvent],
	);

	useEffect(() => {
		if (isOpen) {
			fetchCustomFields();
		}
	}, [fetchCustomFields, isOpen]);

	const scopeFilterDescription = formatMessage(
		fg('fix_ip_checking_issue_count_without_filter_value')
			? messages.scopeFilterDescription
			: messages.customFieldSettingDescription,
		{
			bold: (chunks: ReactNode) => <b>{chunks}</b>,
		},
	);

	const iterationLengthOptions = [
		{ label: formatMessage(planIncrementCommonMessages.weeksOption, { count: 1 }), value: 1 },
		{ label: formatMessage(planIncrementCommonMessages.weeksOption, { count: 2 }), value: 2 },
		{ label: formatMessage(planIncrementCommonMessages.weeksOption, { count: 3 }), value: 3 },
		{ label: formatMessage(planIncrementCommonMessages.weeksOption, { count: 4 }), value: 4 },
	];

	const defaultLengthOption = iterationLengthOptions.find(
		({ value }) => value === DEFAULT_ITERATION_LENGTH,
	);

	return (
		<ModalTransition>
			{isOpen && (
				<Modal onClose={handleCancelButtonClick} autoFocus={focusRef} shouldReturnFocus>
					<ModalHeader>
						<div>
							<ModalTitle>{formatMessage(messages.addIncrementTitle)}</ModalTitle>
							{fg('replace_typography_css_with_tokens_arj') ? (
								<Box xcss={paragraphStyles}>
									<Text as="p">
										{formatMessage(
											fg('program_board_flexible_cadence')
												? messages.addIncrementDescriptionNew
												: messages.addIncrementDescription,
										)}
										{fg('program_board_flexible_cadence') && (
											<>
												<br />
												<Link href={DOC_LINK} target="_blank">
													{formatMessage(messages.doclink)}
												</Link>
											</>
										)}
									</Text>
								</Box>
							) : (
								<p>{formatMessage(messages.addIncrementDescription)}</p>
							)}
						</div>
						<ModalClose>
							<Button appearance="link" spacing="none" onClick={handleCancelButtonClick}>
								<CrossIcon
									label={formatMessage(messages.closeModal)}
									primaryColor={token('color.text.subtle', N500)}
								/>
							</Button>
						</ModalClose>
					</ModalHeader>

					<Form<FormData> onSubmit={handleSubmitForm}>
						{({ formProps, submitting }) => (
							<FormStyled {...formProps}>
								<ModalBody>
									{fg('program_board_flexible_cadence') ? (
										<Stack space="space.100">
											<Stack>
												<Inline space="space.100">
													<Box xcss={FieldBoxStyles}>
														<Field
															aria-required
															name="name"
															testId="portfolio-3-plan-increment-common.ui.increment-creation.name-field"
															label={formatMessage(
																fg('program_board_flexible_cadence')
																	? messages.nameShort
																	: messages.name,
															)}
															defaultValue=""
														>
															{
																// eslint-disable-next-line @typescript-eslint/no-shadow
																({ fieldProps, error, valid }) => (
																	<>
																		<TextField
																			autoComplete="off"
																			placeholder={formatMessage(messages.namePlaceholder)}
																			maxLength={255}
																			{...fieldProps}
																			ref={focusRef}
																		/>
																		{valid ||
																			(error && (
																				<ErrorMessage testId="portfolio-3-plan-increment-common.ui.increment-creation.user-submission-error">
																					{error}
																				</ErrorMessage>
																			))}
																	</>
																)
															}
														</Field>
													</Box>
													<Box xcss={FieldBoxStyles}>
														<Field
															aria-required
															name="start"
															testId="portfolio-3-plan-increment-common.ui.increment-creation.start-date-field"
															label={formatMessage(
																fg('program_board_flexible_cadence')
																	? messages.startDateShort
																	: messages.startDate,
															)}
															defaultValue={getStartDate()}
														>
															{
																// eslint-disable-next-line @typescript-eslint/no-shadow
																({ fieldProps, error }) => (
																	<>
																		<DateTimePicker
																			{...fieldProps}
																			locale={locale}
																			datePickerProps={{
																				weekStartDay: getWeekStartDay(locale),
																			}}
																			timePickerProps={{
																				times: getDefaultTimes(),
																			}}
																			testId="portfolio-3-plan-increment-common.ui.increment-creation.start-date-picker"
																		/>
																		{error && <ErrorMessage>{error}</ErrorMessage>}
																	</>
																)
															}
														</Field>
													</Box>
												</Inline>
												<Inline space="space.100">
													<Box xcss={FieldBoxStyles}>
														<Field<ValueType<NumberOption>>
															label={formatMessage(messages.iterationLength)}
															name="iterationLength"
															defaultValue={defaultLengthOption}
														>
															{({ fieldProps }) => (
																<Select<NumberOption>
																	{...fieldProps}
																	options={iterationLengthOptions}
																	styles={{
																		menuPortal: (base) => ({
																			...base,

																			zIndex: layers.modal,
																		}),
																	}}
																	menuPortalTarget={document.body}
																/>
															)}
														</Field>
													</Box>
													<Box xcss={FieldBoxStyles}>
														{' '}
														<Field<ValueType<NumberOption>>
															label={formatMessage(messages.iterationsCount)}
															name="iterationsCount"
															defaultValue={ITERATIONS_COUNT_OPTIONS.find(
																({ value }) => value === DEFAULT_ITERATIONS_COUNT,
															)}
														>
															{({ fieldProps }) => <IterationsCount {...fieldProps} />}
														</Field>
													</Box>
												</Inline>
											</Stack>
											<CalculatedBoardEndDate />
										</Stack>
									) : (
										<>
											<Field
												aria-required
												name="name"
												testId="portfolio-3-plan-increment-common.ui.increment-creation.name-field"
												label={formatMessage(messages.name)}
												defaultValue=""
											>
												{
													// eslint-disable-next-line @typescript-eslint/no-shadow
													({ fieldProps, error, valid }) => (
														<>
															<TextField
																autoComplete="off"
																placeholder={formatMessage(messages.namePlaceholder)}
																maxLength={255}
																{...fieldProps}
																ref={focusRef}
															/>
															{valid ||
																(error && (
																	<ErrorMessage testId="portfolio-3-plan-increment-common.ui.increment-creation.user-submission-error">
																		{error}
																	</ErrorMessage>
																))}
														</>
													)
												}
											</Field>
											<Field
												aria-required
												name="start"
												testId="portfolio-3-plan-increment-common.ui.increment-creation.start-date-field"
												label={formatMessage(messages.startDate)}
												defaultValue={getStartDate()}
											>
												{
													// eslint-disable-next-line @typescript-eslint/no-shadow
													({ fieldProps, error }) => (
														<>
															<DateTimePicker
																{...fieldProps}
																locale={locale}
																datePickerProps={{
																	weekStartDay: getWeekStartDay(locale),
																}}
																timePickerProps={{
																	times: getDefaultTimes(),
																}}
																testId="portfolio-3-plan-increment-common.ui.increment-creation.start-date-picker"
															/>
															{error && <ErrorMessage>{error}</ErrorMessage>}
														</>
													)
												}
											</Field>
										</>
									)}

									<FormSection>
										<Heading size="medium">
											{formatMessage(
												fg('fix_ip_checking_issue_count_without_filter_value')
													? messages.scopeFilterHeading
													: messages.customFieldSettingHeading,
											)}
										</Heading>

										{fg('replace_typography_css_with_tokens_arj') ? (
											<Box xcss={paragraphStyles}>
												<Text as="p">{scopeFilterDescription}</Text>
											</Box>
										) : (
											<p>{scopeFilterDescription}</p>
										)}
										<Inline spread="space-between" space="space.100">
											<Box xcss={FieldBoxStyles}>
												<Field<ValueType<Option>>
													aria-required
													name="filterFieldId"
													testId="portfolio-3-plan-increment-common.ui.increment-creation.custom-field"
													label={formatMessage(
														fg('fix_ip_checking_issue_count_without_filter_value')
															? messages.scopeFilterFieldLabel
															: messages.customFieldSettingFieldLabel,
													)}
												>
													{({
														fieldProps: { id, ...rest },
														// eslint-disable-next-line @typescript-eslint/no-shadow
														error,
													}) => (
														<>
															<Select<Option>
																inputId={id}
																isLoading={isLoadingCustomFields}
																isClearable
																isSearchable
																closeMenuOnScroll
																styles={{
																	menuPortal: (base) => ({
																		...base,

																		zIndex: layers.modal,
																	}),
																}}
																menuPortalTarget={document.body}
																placeholder={formatMessage(
																	fg('fix_ip_checking_issue_count_without_filter_value')
																		? messages.scopeFilterFieldPlaceholder
																		: messages.customFieldSettingFieldPlaceholder,
																)}
																options={customFieldOptions}
																components={{
																	Option: BoardScopeFilterOption,
																	SingleValue: BoardScopeFilterSingleValue,
																	Control: BoardScopeFilterControl,
																}}
																{...rest}
															/>
															{error && <ErrorMessage>{error}</ErrorMessage>}
														</>
													)}
												</Field>
											</Box>
											<ScopeFilterValue />
										</Inline>
									</FormSection>

									{!isNil(error) && (
										<FormSection>
											<SectionMessage appearance="error">
												{fg('replace_typography_css_with_tokens_arj') ? (
													<Box xcss={paragraphStyles}>
														<Text as="p">{formatMessage(messages.errorLabel)}</Text>
													</Box>
												) : (
													<p>{formatMessage(messages.errorLabel)}</p>
												)}
											</SectionMessage>
										</FormSection>
									)}
								</ModalBody>
								<ModalFooter>
									<IssueCount initialStartDate={getStartDate()} />
									<ButtonGroup>
										<Button
											appearance="subtle"
											testId="portfolio-3-plan-increment-common.ui.increment-creation.cancel-button"
											onClick={handleCancelButtonClick}
											isDisabled={saving || submitting}
										>
											{formatMessage(messages.cancel)}
										</Button>
										<Button
											type="submit"
											testId="portfolio-3-plan-increment-common.ui.increment-creation.submit-button"
											appearance="primary"
											isDisabled={saving || submitting}
										>
											{formatMessage(messages.submit)}
										</Button>
									</ButtonGroup>
								</ModalFooter>
							</FormStyled>
						)}
					</Form>
				</Modal>
			)}
		</ModalTransition>
	);
};

export default IncrementCreation;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ModalClose = styled.div({
	alignSelf: 'flex-start',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FormStyled = styled.form({
	marginTop: 0,
});

const FieldBoxStyles = xcss({
	width: '50%',
});

const paragraphStyles = xcss({
	marginTop: 'space.150',
});
