import React from 'react';
import Button from '@atlaskit/button/new';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, type AnalyticsEvent } from '@atlassian/jira-product-analytics-bridge';
import type { StandupActions } from '../../../common/types';
import messages from './messages';

export const EndStandupButton = ({ setIsActiveStandup }: StandupActions) => {
	const { formatMessage } = useIntl();

	const handleExitStandup = (analyticsEvent: AnalyticsEvent) => {
		fireUIAnalytics(analyticsEvent, 'exitStandup');
		setIsActiveStandup(false);
	};

	return (
		<Button onClick={(__, analyticsEvent) => handleExitStandup(analyticsEvent)}>
			{formatMessage(messages.endStandupButtonNonfinal)}
		</Button>
	);
};
