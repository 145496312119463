import queryString from 'query-string';
import { COMPANY_MANAGED_BACKLOG } from '@atlassian/jira-capabilities/src/constants';
import { setMark, setMeasure } from '@atlassian/jira-common-performance/src/marks.tsx';
import { getTraceId } from '@atlassian/jira-early-script-utils';
import { FetchError } from '@atlassian/jira-early-script-utils/src/types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { JiraPlanDataRestResponse } from '@atlassian/jira-software-backlog-uif-types';
import { getEnhancedCapabilityHeader } from '@atlassian/jira-ufo-capability-header';
import { getActiveTraceHttpRequestHeaders } from '@atlassian/react-ufo/experience-trace-id-context';
import { getBacklogCachedEntry, type BacklogCachedEntry } from '../uif-backlog-cache';
import { extractErrorMessage, parseBacklogLocation } from '../utils';
import type { ParsedQueryParameters } from '../utils/types';
import { buildPlanDataFetchOptions } from './utils';

// Removing isIndexedDBCachingEnabledForBacklog will make this obsolete.
let backlogResponsePromise: Promise<JiraPlanDataRestResponse> | null;

type BacklogResponse = {
	requestPromise: Promise<JiraPlanDataRestResponse>;
	cachedPromise: Promise<BacklogCachedEntry | null>;
};

let backlogResponse: BacklogResponse | null = null;

export const putEarlyScriptResponseOld = (response: Promise<JiraPlanDataRestResponse>) => {
	backlogResponsePromise = response;
};

export const takeEarlyScriptResponseOld = (): Promise<JiraPlanDataRestResponse> | null => {
	const response = backlogResponsePromise;
	backlogResponsePromise = null;
	return response;
};

export const putEarlyScriptResponse = (response: BacklogResponse) => {
	backlogResponse = response;
};

export const takeEarlyScriptResponse = (): BacklogResponse | null => {
	const response = backlogResponse;
	backlogResponse = null;
	return response;
};
const onEnd = () => {
	setMark('uif-backlog-fetch-end');
	setMeasure(
		'uif-backlog-fetch/request',
		'uif-backlog-fetch-start-request',
		'uif-backlog-fetch-end',
	);
	setMeasure('uif-backlog-fetch', 'uif-backlog-fetch-start', 'uif-backlog-fetch-end');
};

export const fetchUIFBacklogDataOld = async (
	boardId: number,
	queryParameters: ParsedQueryParameters,
): Promise<JiraPlanDataRestResponse> => {
	const earlyScriptResponse = takeEarlyScriptResponseOld();
	if (earlyScriptResponse) {
		return earlyScriptResponse;
	}
	setMark('uif-backlog-fetch-start');

	const fetchUIFBacklogDataParams = buildPlanDataFetchOptions(boardId, queryParameters);
	const queryStr = queryString.stringify(fetchUIFBacklogDataParams);

	setMark('uif-backlog-fetch-start-request');

	const backlogDataUrl = `/rest/greenhopper/1.0/xboard/plan/v2/backlog/data?${queryStr}`;

	const enhancedCapabilityHeader = getEnhancedCapabilityHeader(COMPANY_MANAGED_BACKLOG);
	const headers = {
		...(enhancedCapabilityHeader ? { 'X-Atlassian-Capability': enhancedCapabilityHeader } : {}),
	};

	Object.assign(headers, getActiveTraceHttpRequestHeaders(backlogDataUrl));

	const res = await fetch(backlogDataUrl, { headers });

	const json = await res.json().catch(() => null);
	if (!res.ok) {
		onEnd();
		const traceId = getTraceId(res);
		const message = extractErrorMessage(json);
		if (traceId) {
			throw new FetchError(res.status, message, traceId);
		} else {
			throw new FetchError(res.status, message);
		}
	}

	onEnd();
	return json;
};

export const fetchUIFBacklogData = (
	boardId: number,
	queryParameters: ParsedQueryParameters,
): BacklogResponse => {
	const earlyScriptResponse = takeEarlyScriptResponse();
	if (earlyScriptResponse) {
		return earlyScriptResponse;
	}

	const requestPromise = (async () => {
		setMark('uif-backlog-fetch-start');
		const fetchUIFBacklogDataParams = buildPlanDataFetchOptions(boardId, queryParameters);
		const queryStr = queryString.stringify(fetchUIFBacklogDataParams);

		setMark('uif-backlog-fetch-start-request');

		const backlogDataUrl = `/rest/greenhopper/1.0/xboard/plan/v2/backlog/data?${queryStr}`;

		const enhancedCapabilityHeader = getEnhancedCapabilityHeader(COMPANY_MANAGED_BACKLOG);
		const headers = {
			...(enhancedCapabilityHeader ? { 'X-Atlassian-Capability': enhancedCapabilityHeader } : {}),
		};

		Object.assign(headers, getActiveTraceHttpRequestHeaders(backlogDataUrl));

		const res = await fetch(backlogDataUrl, { headers });

		const json = await res.json().catch(() => null);
		if (!res.ok) {
			onEnd();
			const traceId = getTraceId(res);
			const message = extractErrorMessage(json);
			if (traceId) {
				throw new FetchError(res.status, message, traceId);
			} else {
				throw new FetchError(res.status, message);
			}
		}

		onEnd();
		return json;
	})();

	const cachedPromise = getBacklogCachedEntry(boardId);

	return { requestPromise, cachedPromise };
};

export function runUIFBacklogEarlyScript(location: Location): boolean {
	const parsedResult = parseBacklogLocation(location);
	if (!parsedResult) {
		return false;
	}

	const { boardId, queryParameters } = parsedResult;

	if (fg('indexeddb_cache_for_backlog')) {
		const backlogData = fetchUIFBacklogData(boardId, queryParameters);
		putEarlyScriptResponse(backlogData);
	} else {
		const backlogData = fetchUIFBacklogDataOld(boardId, queryParameters);
		putEarlyScriptResponseOld(backlogData);
	}

	return true;
}
