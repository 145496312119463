import {
	TEXT,
	ASSIGNEE,
	LABEL,
	ISSUE_PARENT,
	ISSUE_PROJECT,
	ISSUE_TYPE,
	VERSION,
	CUSTOM_FILTER,
	COMPONENT,
	REQUEST_TYPE,
	STATUS_CATEGORY,
	STATUS,
	QUICK_FILTER,
	SPRINT,
	GOAL,
} from '../common/constants';
import type { Filters } from '../common/types';
import { assigneeMatcherCreator } from './matcher-creators/assignee.tsx';
import { componentMatcherCreator } from './matcher-creators/component.tsx';
import { filteredIssueIdsMatcherCreator } from './matcher-creators/filtered-issue-ids.tsx';
import { issueParentMatcherCreator } from './matcher-creators/issue-parent.tsx';
import { issueProjectMatcherCreator } from './matcher-creators/issue-project.tsx';
import { issueTypeMatcherCreator } from './matcher-creators/issue-type.tsx';
import { labelMatcherCreator } from './matcher-creators/label.tsx';
import { requestTypeMatcherCreator } from './matcher-creators/request-type.tsx';
import { sprintMatcherCreator } from './matcher-creators/sprint.tsx';
import { statusCategoryMatcherCreator } from './matcher-creators/status-category.tsx';
import { statusMatcherCreator } from './matcher-creators/status.tsx';
import { textMatcherCreator } from './matcher-creators/text.tsx';
import { versionMatcherCreator } from './matcher-creators/version.tsx';
import type { Context, MatcherCreators, Matcher, FilterableIssue } from './types';

export const createDefaultMatcherCreators = <
	Issue extends FilterableIssue,
>(): MatcherCreators<Issue> => ({
	[TEXT]: textMatcherCreator,
	[ASSIGNEE]: assigneeMatcherCreator,
	[LABEL]: labelMatcherCreator,
	[ISSUE_PARENT]: issueParentMatcherCreator,
	[ISSUE_PROJECT]: issueProjectMatcherCreator,
	[ISSUE_TYPE]: issueTypeMatcherCreator,
	[VERSION]: versionMatcherCreator,
	[CUSTOM_FILTER]: filteredIssueIdsMatcherCreator,
	[COMPONENT]: componentMatcherCreator,
	[REQUEST_TYPE]: requestTypeMatcherCreator,
	[STATUS_CATEGORY]: statusCategoryMatcherCreator,
	[STATUS]: statusMatcherCreator,
	[QUICK_FILTER]: filteredIssueIdsMatcherCreator,
	[SPRINT]: sprintMatcherCreator,
	[GOAL]: filteredIssueIdsMatcherCreator,
});

const createMatchers = <Issue extends FilterableIssue>(
	filter: Filters,
	context: Context,
	matcherCreators: MatcherCreators<Issue>,
): Matcher<Issue>[] =>
	Object.keys(filter).reduce<Matcher<Issue>[]>((acc, key) => {
		// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'Filters'.
		const filterValue = filter[key];
		// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'MatcherCreators<Issue>'.
		const matcherCreator = matcherCreators[key];

		if (filterValue === undefined || filterValue.length <= 0 || matcherCreator === undefined)
			return acc;

		acc.push(matcherCreator(filterValue, context));
		return acc;
	}, []);

export const createFilterFunction = <Issue extends FilterableIssue>(
	filter: Filters,
	context: Context,
	matcherCreators?: MatcherCreators<Issue>,
	overrideMatchers?: Matcher<Issue>[],
): Matcher<Issue> => {
	const matchers = createMatchers<Issue>(
		filter,
		context,
		matcherCreators ?? createDefaultMatcherCreators<Issue>(),
	);

	return (issue: Issue) =>
		matchers.every((matcher) => matcher(issue)) ||
		(overrideMatchers !== undefined && overrideMatchers.some((matcher) => matcher(issue)));
};

export {
	textMatcherCreator,
	assigneeMatcherCreator,
	labelMatcherCreator,
	issueParentMatcherCreator,
	issueProjectMatcherCreator,
	issueTypeMatcherCreator,
	versionMatcherCreator,
	filteredIssueIdsMatcherCreator,
	componentMatcherCreator,
	requestTypeMatcherCreator,
	statusCategoryMatcherCreator,
	statusMatcherCreator,
	sprintMatcherCreator,
};

export type { Context, MatcherCreators, Matcher, FilterableIssue };
