import React, { useContext } from 'react';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container';
import type { ParticipantListProps, StandupActions } from './src/common/types';
import { StandupEntryPointContext } from './src/controllers/context-provider';
import { LoadingStandupPanel } from './src/ui/loading-standup-panel';

export const AsyncJiraStandups = (props: ParticipantListProps & StandupActions) => {
	const { entryPointReferenceSubject } = useContext(StandupEntryPointContext);

	return (
		<JiraEntryPointContainer
			id="jira-rituals-standups"
			packageName="jiraRitualsStandups"
			fallback={<LoadingStandupPanel />}
			entryPointReferenceSubject={entryPointReferenceSubject}
			runtimeProps={props}
		/>
	);
};
