import React, { useContext, useRef, useEffect, useCallback } from 'react';
import flow from 'lodash/flow';
import { AnalyticsSubject } from '@atlassian/jira-analytics-web-react/src/components/decorators.tsx';
import type FavouriteButtonStateful from '@atlassian/jira-favourite-button';
import FavoriteButtonStateless from '@atlassian/jira-favourite-button-stateless/src/view/view.tsx';
import { FavoriteChangeContext } from '@atlassian/jira-favourite-change-provider/src/view/index.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useBoardSelector } from '../../../state';
import { isFavouriteSelector } from '../../../state/selectors/board/board-selectors';

export const FavoriteButton = (props: React.ComponentProps<typeof FavouriteButtonStateful>) => {
	const { itemId, itemType } = props;
	const isFavoriteInitial = useBoardSelector(isFavouriteSelector);
	const { items, changeFavorite } = useContext(FavoriteChangeContext);
	const changeItem = items[itemType]?.[itemId];
	const isFavorite = changeItem ? changeItem.value : isFavoriteInitial ?? false;
	const isPending = changeItem ? changeItem.pending : isFavoriteInitial === undefined;
	const skipAnimation = useRef(true);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		if (isFavoriteInitial !== undefined) {
			skipAnimation.current = false;
			const item = items[itemType];
			if (item && !item[itemId]) {
				item[itemId] = {
					id: itemId,
					type: itemType,
					value: isFavoriteInitial,
					pending: false,
				};
			}
		}
	}, [isFavoriteInitial, itemId, itemType, items]);

	const onClick = useCallback(() => {
		const analyticsEvent = createAnalyticsEvent({
			action: 'clicked',
			actionSubject: 'favouriteButton',
		});
		changeFavorite(
			{
				id: itemId,
				type: itemType,
				value: !isFavorite,
			},
			analyticsEvent,
		);
	}, [changeFavorite, createAnalyticsEvent, isFavorite, itemId, itemType]);

	return (
		<FavoriteButtonStateless
			{...props}
			isFavorite={isFavorite}
			pending={isPending}
			onClick={onClick}
			skipAnimation={skipAnimation.current}
		/>
	);
};

export default flow(AnalyticsSubject('board'))(FavoriteButton);
