import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import log from '@atlassian/jira-common-util-logging/src/log';
import { getFavorite } from '@atlassian/jira-favourite-button/src/rest/get-favorite.tsx';
import { BOARDS_ITEM_TYPE } from '@atlassian/jira-favourite-change-provider/src/model/constants.tsx';
import { Capability } from '../../common/capability';
import { setIsFavourite } from '../../state/actions/board/board-favourite';
import { SOFTWARE_APP_LOADED } from '../../state/actions/software';
import { getCapability } from '../../state/selectors/capability/capability-selectors';
import { contextPathSelector } from '../../state/selectors/software/software-selectors';
import type { Action, ActionsObservable, MiddlewareAPI } from '../../state/types';

export function boardFavouriteEpic(
	action$: ActionsObservable,
	store: MiddlewareAPI,
): Observable<Action> {
	return action$.ofType(SOFTWARE_APP_LOADED).mergeMap(() => {
		const state = store.getState();
		const rapidViewId = state.configuration.rapidViewId;
		const shouldRenderFavouriteButton = getCapability(state)(
			Capability.RENDER_BOARD_FAVOURITE_HEADER_BUTTON,
		);

		if (!rapidViewId || !shouldRenderFavouriteButton) {
			return Observable.empty<Action>();
		}

		const baseUrl = contextPathSelector(state);
		return Observable.fromPromise(getFavorite(baseUrl, { id: rapidViewId, type: BOARDS_ITEM_TYPE }))
			.flatMap((isFavourite) => Observable.of(setIsFavourite(isFavourite)))
			.catch((error) => {
				log.safeErrorWithoutCustomerData(
					'board.get-favourite.failure',
					'Board failed to get favourite',
					error,
				);

				return Observable.empty<Action>();
			});
	});
}
